import { Component, OnInit } from '@angular/core';
import { UsuarioLogin } from '../clases/usuario-login';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { CamposPersonalizadosServiceService } from '../services/campos-personalizados-service.service';
import { RouterExtService } from '../services/router-ext-service.service';
import { Principios } from '../clases/principios';
import { PrincipiosInformacion } from '../clases/principios-informacion';
import { Observable, interval } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { StorageServiceService } from '../services/storage-service.service';
import { UtilServiceService } from '../services/util-service.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PrincipioPlantilla } from '../clases/prinpicio-plantilla';
import { ListaPrincipiosUsuario } from '../clases/lista-principios-usuario';

declare var $: any;
declare var steps: any;

@Component({
  templateUrl: './valores.component.html',
  styleUrls: ['./valores.component.css']
})
export class ValoresComponent implements OnInit {

  //RESULTADO DEL MODAL
  closeResult = '';

  //formas
  usuario: UsuarioLogin;
  formGroupValor: FormGroup;
  formGroupMision: FormGroup;
  formGroupVision: FormGroup;

  //variables principios fundamentales
  valor: string;
  mision: string;
  vision: string;
  principiosTmp: Principios;
  loading = false;
  submittedValor = false;
  submittedVision = false;
  submittedMision = false;
  actualizarMision = false;
  actualizarVision = false;
  actualizarValor = false;
  fundamentales = false;
  returnUrl: string;
  principiosAll: PrincipiosInformacion[];
  principiosValores: PrincipiosInformacion[];
  principioMision: PrincipiosInformacion;
  principioVision: PrincipiosInformacion;
  principiosPlantilla: PrincipioPlantilla[];
  listaPrincipiosUsuario: ListaPrincipiosUsuario;
  listaPrincipio: PrincipiosInformacion[]=[];

  // variables principios imperativos
  actualizarPromesa = false;
  actualizarReglaDeOro = false;
  reglasDeOro: PrincipiosInformacion[];
  promesa: PrincipiosInformacion;
  submittedPromesa = false;
  submittedReglaDeOro = false;

  formGroupPromesa: FormGroup;
  formGroupReglaDeOro: FormGroup;

  actiMenu = false;
  mostrarLayoutHome = true;
  
  //repuestas
  error = '';
  errorVision = '';
  errorValor = '';
  errorMision = '';
  errorPromesa = '';
  errorRegla = '';

  respuesta = '';  
  respuestaValor = '';  
  respuestaMision = '';  
  respuestaVision = '';
  respuestaPromesa = '';
  respuestaRegla = '';

  hiddenButonVision = false;
  hiddenButonMision = false;
  hiddenButonPromesa = false;

  //header
  anio: number;
  today = new Date();

  idPrincipio: number;
  nombreParametro: string;

  constructor(public utilServiceService : UtilServiceService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router, private clienteWebService: ClienteWebServiceService,
    private formControlPersonalizados: CamposPersonalizadosServiceService,
    private routerExtService: RouterExtService,
    public authenticationService: ClienteWebServiceService,
    private storageService: StorageServiceService,
    private modalService: NgbModal  //PARA MOSTRAR LA VENTANA MODAL 
  ) {
    this.authenticationService.currentUser.subscribe(x => {this.usuario = x;
      if (this.usuario) {
        if(this.usuario.roles === null){
          this.router.navigate(['/home']);
        }
      }
    });
    this.anio = this.storageService.obtenerAnioActual();
    if(this.anio === undefined){
      this.anio = this.today.getFullYear();
    }
  }

  ngOnInit() {    
     //  this.efectoTab();
       this.cargarPrincipiosFundamentales();
       this.cargarPrincipiosImperativos();
  }

  efectoTab(){
    $(function(){
      $("#wizard").steps({
            headerTag: "h2",
            bodyTag: "section",
            transitionEffect: "fade",
            enableAllSteps: true,
            transitionEffectSpeed: 500,
            labels: {
                finish: "Guardar",
                next: "Siguiente",
                previous: "Anterior"
            }
        });
        $('.wizard > .steps li a').click(function(){
          $(this).parent().addClass('checked');
        $(this).parent().prevAll().addClass('checked');
        $(this).parent().nextAll().removeClass('checked');
        });
        // Custome Jquery Step Button
        $('.Siguiente').click(function(){
          $("#wizard").steps('next');
        })
        $('.Anterior').click(function(){
            $("#wizard").steps('previous');
        })
        // Select Dropdown
        $('html').click(function() {
            $('.select .dropdown').hide(); 
        });
        $('.select').click(function(event){
            event.stopPropagation();
        });
        $('.select .select-control').click(function(){
            $(this).parent().next().toggle();
        })    
        $('.select .dropdown li').click(function(){
            $(this).parent().toggle();
            var text = $(this).attr('rel');
            $(this).parent().prev().find('div').text(text);
        })
    })
  }

  // Seccion principios Imperativos
  cargarPrincipiosImperativos(){
    this.authenticationService.obtenerPrincipios().subscribe(data => {
      if(data){
      this.principiosAll = data.listaPrincipio;

      this.reglasDeOro = this.principiosAll.filter(function (principio) {
        return principio.idPrincipio === 4;
      });

      if(this.reglasDeOro){
         this.reglasDeOro = this.reglasDeOro.sort((a, b)=>{
          if (a.valor > b.valor) {
            return 1;
          }
          if (a.valor < b.valor) {
            return -1;
          }
          return 0;
        });
      }

      this.promesa = this.principiosAll.filter(datos => datos.idPrincipio === 5)[0];
    }else{
      this.promesa = null;
      this.reglasDeOro = null;
    }
      if (this.promesa) {
        this.hiddenButonPromesa = true;
        this.actualizarPromesa = true;
      }

      //Inica las formas
      this.formGroupPromesa = this.formBuilder.group({
        promesa: [this.promesa? this.promesa.valor:'', Validators.required],
        idPrincipioUsuario: [this.promesa? this.promesa.idPrincipioUsuario : '']
      });

      this.formGroupReglaDeOro = this.formBuilder.group({
        reglaDeOro: ['', Validators.required],
        idPrincipioUsuario: ['']
      });
    },
      error => {
        console.log(error.message);
      }
    );
  }


  get fPromesa(){return this.formGroupPromesa.controls};

  get fReglaDeOro(){return this.formGroupReglaDeOro.controls};


  onSubmitPromesa(){
    this.submittedPromesa = true;

    if (this.formGroupPromesa.invalid) {
      return;
    }

    this.valor = this.fPromesa.promesa.value;
    this.principiosTmp = new Principios();
    this.principiosTmp.codigoEmpleado = this.usuario.codigoEmpleado;
    this.principiosTmp.idInstancia = this.usuario.idInstancia;
    this.principiosTmp.nombreUsuario = this.usuario.nombreUsuario;
    this.principiosTmp.idPrincipio = 5;
    this.principiosTmp.valor = this.valor;

    if(this.actualizarPromesa){
      this.principiosTmp.idPrincipioUsuario = this.fPromesa.idPrincipioUsuario.value;
      this.actualizarInformacion(this.principiosTmp,'P');
    } else {
      this.guardarInformacion(this.principiosTmp,'P');
    }

    this.actualizarPromesa = false;
    this.intervalMensajeActive();
  }

  onSubmitReglaDeOro(){
    if(this.listaPrincipio && this.listaPrincipio.length ==0){
    this.submittedReglaDeOro = true;

    if (this.formGroupReglaDeOro.invalid) {
      return;
    }

    this.valor = this.fReglaDeOro.reglaDeOro.value;
    this.principiosTmp = new Principios();
    this.principiosTmp.codigoEmpleado = this.usuario.codigoEmpleado;
    this.principiosTmp.idInstancia = this.usuario.idInstancia;
    this.principiosTmp.nombreUsuario = this.usuario.nombreUsuario;
    this.principiosTmp.idPrincipio = 4;
    this.principiosTmp.valor = this.valor;

    if(this.actualizarReglaDeOro){
      this.principiosTmp.idPrincipioUsuario = this.fReglaDeOro.idPrincipioUsuario.value;
      this.actualizarInformacion(this.principiosTmp,'R');
    } else {
      this.guardarInformacion(this.principiosTmp,'R');
    }

    this.actualizarReglaDeOro = false;
    this.intervalMensajeActive();
  }else{
    if (!this.formGroupReglaDeOro.invalid) {
      let principio = new PrincipiosInformacion();
      principio.valor = this.fReglaDeOro.reglaDeOro.value;
      principio.idPrincipio = this.listaPrincipio[0].idPrincipio;
      this.listaPrincipio.push(principio);
    }
    this.listaPrincipiosUsuario = new ListaPrincipiosUsuario();
    this.listaPrincipiosUsuario.codigoEmpleado = this.usuario.codigoEmpleado;
    this.listaPrincipiosUsuario.idInstancia = this.usuario.idInstancia;
    this.listaPrincipiosUsuario.nombreUsuario = this.usuario.nombreUsuario;
    this.listaPrincipiosUsuario.listaPrincipio = this.listaPrincipio;
    
    this.clienteWebService.registrarListaValoresYReglas(this.listaPrincipiosUsuario).subscribe(respuesta =>{
      if (respuesta.respuesta === 'ok') {
        this.respuestaRegla = 'Dato guardado exitosamente.';
        this.submittedReglaDeOro = false;
        this.cargarPrincipiosFundamentales();
        this.cargarPrincipiosImperativos();
        this.obtenerParametro();

        this.actualizarReglaDeOro = false;
        this.intervalMensajeActive();
      }
    }, error =>{
      this.errorRegla = error.error;
      this.submittedReglaDeOro = false;
    });

  }

  }

  onClickReglaDeOro(idPrincipioUsuario: number, valor: String) {
    this.actualizarReglaDeOro = true;
    this.fReglaDeOro.reglaDeOro.setValue(valor);
    this.fReglaDeOro.idPrincipioUsuario.setValue(idPrincipioUsuario);
  }


  // Seccion principios Fundamentales
  cargarPrincipiosFundamentales() {
    this.authenticationService.obtenerPrincipios().subscribe(data => {

      if(data){
      this.principiosAll = data.listaPrincipio;

      this.principiosValores = this.principiosAll.filter(function (principio) {
        return principio.idPrincipio === 1;
      });
      
      if(this.principiosValores){
          this.principiosValores = this.principiosValores.sort((a, b)=>{
            if (a.valor > b.valor) {
              return 1;
            }
            if (a.valor < b.valor) {
              return -1;
            }
            return 0;
          });
      }

      this.principioMision = this.principiosAll.filter(datos => datos.idPrincipio === 3)[0];
      this.principioVision = this.principiosAll.filter(datos => datos.idPrincipio === 2)[0];
    }else{
      this.principioMision = null;
      this.principioVision = null;
      this.principiosValores = null;
    }
      if (this.principioMision) {        
        this.hiddenButonMision = true;
        this.actualizarMision = true;
      }

      if (this.principioVision) {
        this.hiddenButonVision = true;
        this.actualizarVision = true;
      }

      

      //Inica las formas
      this.formGroupValor = this.formBuilder.group({
        valor: ['', Validators.required],
        idPrincipioUsuario: ['']
      });

      this.formGroupMision = this.formBuilder.group({
        mision: [this.principioMision? this.principioMision.valor : '', Validators.required],
        idPrincipioUsuario: [this.principioMision? this.principioMision.idPrincipioUsuario : '']
      });

      this.formGroupVision = this.formBuilder.group({
        vision: [this.principioVision ? this.principioVision.valor : '', Validators.required],
        idPrincipioUsuario: [this.principioVision? this.principioVision.idPrincipioUsuario : '']
      });

      this.toolTip();

    },
      error => {
        console.log(error.message);
      }
    );
  }

  get fValor() {
    return this.formGroupValor.controls;
  }

  get fMision() {
    return this.formGroupMision.controls;
  }

  get fVision() {
    return this.formGroupVision.controls;
  }

  onClickValor(idPrincipioUsuario: number, valor: String) {
    this.actualizarValor = true;
    this.fValor.valor.setValue(valor);
    this.fValor.idPrincipioUsuario.setValue(idPrincipioUsuario);
  }

  onSubmitValor() {

    if(this.listaPrincipio && this.listaPrincipio.length == 0){
      this.submittedValor = true;
      if (this.formGroupValor.invalid) {
        return;
      }

      this.valor = this.fValor.valor.value;
      this.principiosTmp = new Principios();
      this.principiosTmp.codigoEmpleado = this.usuario.codigoEmpleado;
      this.principiosTmp.idInstancia = this.usuario.idInstancia;
      this.principiosTmp.nombreUsuario = this.usuario.nombreUsuario;
      this.principiosTmp.idPrincipio = 1;
      this.principiosTmp.valor = this.valor;

      if(this.actualizarValor){
        this.principiosTmp.idPrincipioUsuario = this.fValor.idPrincipioUsuario.value;
        this.actualizarInformacion(this.principiosTmp,'V');
      } else {
        this.guardarInformacion(this.principiosTmp,'V');
      }

      this.actualizarValor = false;
      this.intervalMensajeActive();
    }else{

      if (!this.formGroupValor.invalid) {
        let principio = new PrincipiosInformacion();
        principio.valor = this.fValor.valor.value;
        principio.idPrincipio = this.listaPrincipio[0].idPrincipio;
        this.listaPrincipio.push(principio);
      }
      this.listaPrincipiosUsuario = new ListaPrincipiosUsuario();
      this.listaPrincipiosUsuario.codigoEmpleado = this.usuario.codigoEmpleado;
      this.listaPrincipiosUsuario.idInstancia = this.usuario.idInstancia;
      this.listaPrincipiosUsuario.nombreUsuario = this.usuario.nombreUsuario;
      this.listaPrincipiosUsuario.listaPrincipio = this.listaPrincipio;
      
      this.clienteWebService.registrarListaValoresYReglas(this.listaPrincipiosUsuario).subscribe(respuesta =>{
        if (respuesta.respuesta === 'ok') {
          this.respuestaValor = 'Dato guardado exitosamente.';
          this.submittedValor = false;
          this.cargarPrincipiosFundamentales();
          this.cargarPrincipiosImperativos();
          this.obtenerParametro();

          this.actualizarValor = false;
          this.intervalMensajeActive();
        }
      }, error =>{
        this.errorValor = error.error;
        this.submittedValor = false;
      });

    }
  }

  onSubmitVision() {
    this.submittedVision = true;

    if (this.formGroupVision.invalid) {
      return;
    }

    this.valor = this.fVision.vision.value;
    this.principiosTmp = new Principios();
    this.principiosTmp.codigoEmpleado = this.usuario.codigoEmpleado;
    this.principiosTmp.idInstancia = this.usuario.idInstancia;
    this.principiosTmp.nombreUsuario = this.usuario.nombreUsuario;
    this.principiosTmp.idPrincipio = 2;
    this.principiosTmp.valor = this.valor;

    if(this.actualizarVision){
      this.principiosTmp.idPrincipioUsuario = this.fVision.idPrincipioUsuario.value;
      this.actualizarInformacion(this.principiosTmp,'VS');
    } else {
      this.guardarInformacion(this.principiosTmp,'VS');
    }

    this.actualizarVision = false;
    this.intervalMensajeActive();
  }

  onSubmitMision() {
    this.submittedMision = true;

    if (this.formGroupMision.invalid) {
      return;
    }

    this.valor = this.fMision.mision.value;
    this.principiosTmp = new Principios();
    this.principiosTmp.codigoEmpleado = this.usuario.codigoEmpleado;
    this.principiosTmp.idInstancia = this.usuario.idInstancia;
    this.principiosTmp.nombreUsuario = this.usuario.nombreUsuario;
    this.principiosTmp.idPrincipio = 3;
    this.principiosTmp.valor = this.valor;

    if (this.actualizarMision) {
      this.principiosTmp.idPrincipioUsuario = this.fMision.idPrincipioUsuario.value;
      this.actualizarInformacion(this.principiosTmp,'M');
    } else {
      this.guardarInformacion(this.principiosTmp,'M');
    }

    this.actualizarMision = false;
  }

  guardarInformacion(principio: Principios, tipoMensaje: String) {
    this.clienteWebService.registrarValores(principio).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.respuesta = 'Dato guardado exitosamente.';
        this.submittedValor = false;
        this.submittedMision = false;
        this.submittedVision = false;
        this.submittedPromesa = false;
        this.submittedReglaDeOro = false;

        if(tipoMensaje ==='V'){
          this.respuestaValor = this.respuesta;
        }
        if(tipoMensaje ==='M'){
          this.hiddenButonMision = true;
          this.respuestaMision = this.respuesta;
        }
        if(tipoMensaje ==='VS'){
          this.hiddenButonVision = true;
          this.respuestaVision = this.respuesta;
        }
        if(tipoMensaje ==='P'){
          this.hiddenButonPromesa = true;
          this.respuestaPromesa = this.respuesta;
        }
        if(tipoMensaje ==='R'){
          this.respuestaRegla = this.respuesta;
        }

          this.cargarPrincipiosFundamentales();
          this.cargarPrincipiosImperativos();

      } else {
        this.error = respuesta.respuesta;
        this.submittedValor = false;
        this.submittedMision = false;
        this.submittedVision = false;
        this.submittedPromesa = false;
        this.submittedReglaDeOro = false;

        if(tipoMensaje ==='V'){
          this.errorValor = this.error;
        }
        if(tipoMensaje ==='M'){
          this.errorMision = this.error;
        }
        if(tipoMensaje ==='VS'){
          this.errorVision = this.error;
        }
        if(tipoMensaje ==='P'){
          this.errorPromesa = this.error;
        }
        if(tipoMensaje ==='R'){
          this.errorRegla = this.error;
        }
      }

    },
      error => {
        this.error = error.error;
        this.submittedValor = false;
        this.submittedMision = false;
        this.submittedVision = false;
        this.submittedPromesa = false;
        this.submittedReglaDeOro = false;

        if(tipoMensaje ==='V'){
          this.errorValor = this.error;
        }
        if(tipoMensaje ==='M'){
          this.errorMision = this.error;
        }
        if(tipoMensaje ==='VS'){
          this.errorVision = this.error;
        }        
        if(tipoMensaje ==='P'){
          this.errorPromesa = this.error;
        }        
        if(tipoMensaje ==='R'){
          this.errorRegla = this.error;
        }

      })

    this.intervalMensajeActive();
  }

  toolTip(){
    $('#wizard-t-0').attr("data-toggle","tooltip");
    $('#wizard-t-0').attr("title","Valores Fundamentales/Creencias");
    /*$('#wizard-t-1').attr("data-toggle","tooltip");
    $('#wizard-t-1').attr("title","Valores Imperativos y Reglas de Oro");*/
  }

  actualizarInformacion(principio: Principios, tipoMensaje: String) {
    this.clienteWebService.actualizarValores(principio).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.respuesta = 'Dato actualizado exitosamente.';
        this.submittedValor = false;
        this.submittedMision = false;
        this.submittedVision = false;
        this.submittedPromesa = false;
        this.submittedReglaDeOro = false;
          this.cargarPrincipiosFundamentales();        
          this.cargarPrincipiosImperativos();
          if(tipoMensaje ==='V'){
            this.respuestaValor = this.respuesta;
          }
          if(tipoMensaje ==='M'){
            this.hiddenButonMision = true;
            this.respuestaMision = this.respuesta;
          }
          if(tipoMensaje ==='VS'){
            this.hiddenButonVision = true;
            this.respuestaVision = this.respuesta;
          }
          if(tipoMensaje ==='P'){
            this.hiddenButonPromesa = true;
            this.respuestaPromesa = this.respuesta;
          }
          if(tipoMensaje ==='R'){
            this.respuestaRegla = this.respuesta;
          }

      } else {
        this.error = respuesta.respuesta;
        this.submittedValor = false;
        this.submittedMision = false;
        this.submittedVision = false;
        this.submittedPromesa = false;
        this.submittedReglaDeOro = false;
      }

      if(tipoMensaje ==='V'){
        this.errorValor = this.error;
      }
      if(tipoMensaje ==='M'){
        this.errorMision = this.error;
      }
      if(tipoMensaje ==='VS'){
        this.errorVision = this.error;
      }
      if(tipoMensaje ==='P'){
        this.errorPromesa = this.error;
      }        
      if(tipoMensaje ==='R'){
        this.errorRegla = this.error;
      }

    },
      error => {
        this.error = error.message;
        this.submittedValor = false;
        this.submittedMision = false;
        this.submittedVision = false;
        this.submittedPromesa = false;
        this.submittedReglaDeOro = false;

        if(tipoMensaje ==='V'){
          this.errorValor = this.error;
        }
        if(tipoMensaje ==='M'){
          this.errorMision = this.error;
        }
        if(tipoMensaje ==='VS'){
          this.errorVision = this.error;
        }
        if(tipoMensaje ==='P'){
          this.errorPromesa = this.error;
        }        
        if(tipoMensaje ==='R'){
          this.errorRegla = this.error;
        }
      });

    this.intervalMensajeActive();
  }

  eliminarValor(principio: Principios){
    this.clienteWebService.eliminarPrincipio(principio).subscribe(respuesta =>{
      this.cargarPrincipiosFundamentales();
    },
    error=>{
      console.log(error)
    }  
  );
  }

  obtenerValoresPrincipiosPlantilla(idPrincipio)
  {
    this.formGroupValor = this.formBuilder.group({
      valor: ['', Validators.required],
      idPrincipioUsuario: ['']
    });

    this.formGroupReglaDeOro = this.formBuilder.group({
      reglaDeOro: ['', Validators.required],
      idPrincipioUsuario: ['']
    });



    this.clienteWebService.obtenerListaActividadPlantilla(idPrincipio).subscribe(principiosPlantilla =>{
      this.principiosPlantilla = principiosPlantilla.listaPrincipioPlantilla;
    })
    
  }

  seleccionarPrincipio(env, principioSelec){
        let principio = new PrincipiosInformacion();
        principio.valor = principioSelec.nombre;
        principio.idPrincipio = principioSelec.idPrincipio;
        

        if(!env){
          var index = this.listaPrincipio.findIndex(a => a.valor === principio.valor && principio.idPrincipio === principio.idPrincipio);
          this.listaPrincipio.splice(index, 1);
        } else {
        this.listaPrincipio.push(principio);
      }
  }

  obtenerParametro(){
    this.clienteWebService.obtenerValorParametro(this.nombreParametro).subscribe( p =>{
      if(p && p.valor){
        this.idPrincipio = Number(p.valor);
        this.obtenerValoresPrincipiosPlantilla(this.idPrincipio);
        
      }
    })
  }

  eliminarReglaDeOro(principio: Principios){
    this.clienteWebService.eliminarPrincipio(principio).subscribe(respuesta =>{
      this.cargarPrincipiosImperativos();
    },
    error=>{
      console.log(error)
    }  
  );
  }

  intervalMensajeActive() {
    const secondsCounter = interval(5000);
    secondsCounter.subscribe(n => {
      this.error = '';
      this.respuesta = '';
      this.errorValor = '';
      this.respuestaValor = '';
      this.errorMision = '';
      this.respuestaMision = '';
      this.errorVision = '';
      this.respuestaVision = '';
      this.errorRegla = '';
      this.respuestaRegla = '';
      this.errorPromesa = '';
      this.respuestaPromesa = '';
    }
    );

    
  }
  

  activarBotonPromesa(){
    this.hiddenButonPromesa = false;
  }

  activarBotonVision(){
    this.hiddenButonVision = false;
  }

  activarBotonMision(){
    this.hiddenButonMision = false;
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  activarMenu() {
    if (this.actiMenu) {
      this.actiMenu = false;
    } else {
      this.actiMenu = true;
    }

  }

  open(content, parametro, actulizar) {
    this.nombreParametro = parametro;
    this.principiosPlantilla = [];
    this.listaPrincipio = [];
    this.formGroupValor = null;
    this.formGroupReglaDeOro = null;

    this.formGroupValor = this.formBuilder.group({
      valor: ['', Validators.required],
      idPrincipioUsuario: ['']
    });

    this.formGroupReglaDeOro = this.formBuilder.group({
      reglaDeOro: ['', Validators.required],
      idPrincipioUsuario: ['']
    });

    this.obtenerParametro();
    
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



}
