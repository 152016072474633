import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilServiceService {
  constructor() { }


  validarRol(roles: String[], rol: String):boolean{
    
    if (roles && roles.length > 0){
        for(let r of roles ){
            if (r === rol){
                return true;
            }
        }
    }

    return false;
  }



}