import { Component, OnInit } from '@angular/core';
import { UsuarioLogin } from '../clases/usuario-login';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { interval } from 'rxjs';
import { UtilServiceService } from '../services/util-service.service';
import { PrincipioPlantilla } from '../clases/prinpicio-plantilla';
import { StorageServiceService } from '../services/storage-service.service';

@Component({
  selector: 'app-plantillavalores',
  templateUrl: './plantillavalores.component.html',
  styleUrls: ['./plantillavalores.component.css']
})
export class PlantillavaloresComponent implements OnInit {

  //AGREGAMOS NUEVOS FORMS PARA LA GESTIóN DE LAS REGLAS DE ORO Y VALORES
  formValor: FormGroup;
  submittedValor = false;

  editarValor : number =-1;
  editarRegla : boolean = false;

  formValorEditar : FormGroup;
  submittedValorEdit = false;

  listaValores : PrincipioPlantilla[];

  errorValor = '';
  respuestaValor = '';  

  formGroup: FormGroup;
  actiMenu = false;
  mostrarLayoutHome = true;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  respuesta = '';
  
  usuario: UsuarioLogin;

  idPrincipio: number;
  nombreParametro: string;
  titulo: string;
  anio;
  today = new Date();

  constructor(public utilServiceService : UtilServiceService,
    private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute, private clienteWebService: ClienteWebServiceService,
    public authenticationService: ClienteWebServiceService,
    private storageService: StorageServiceService
  ) {
    this.authenticationService.currentUser.subscribe(x =>{
      this.usuario = x;
      if (this.usuario) {
        if(this.usuario.roles === null){
          this.router.navigate(['/home']);
        }
      }
    });

    this.nombreParametro = this.route.snapshot.queryParams['nombreParametro'];
    this.titulo = this.route.snapshot.queryParams['titulo'];
    this.anio = this.storageService.obtenerAnioActual();
    if(this.anio === undefined){
      this.anio = this.today.getFullYear();
    }
  }

  ngOnInit() {
    this.clienteWebService.obtenerValorParametro(this.nombreParametro).subscribe( p =>{
      if(p && p.valor){
        this.idPrincipio = Number(p.valor);
        this.obtenerValoresPrincipiosPlantilla(this.idPrincipio);
      }
    })
  }

   
  
  activarMenu() {
    if (this.actiMenu) {
      this.actiMenu = false;
    } else {
      this.actiMenu = true;
    }

  }

  obtenerValoresPrincipiosPlantilla(idPrincipio)
  {

    this.clienteWebService.obtenerListaActividadPlantilla(idPrincipio).subscribe(principiosPlantilla =>{
      //Inicia las formas
      this.formValor = this.formBuilder.group({
        nombre: ['', Validators.required],
        idPrincipio: ['']
      });

      this.formValorEditar = this.formBuilder.group({
        nombre: ['', Validators.required]
      })

      this.listaValores = principiosPlantilla.listaPrincipioPlantilla;
    })
    
  }

  get fModValor()
  {
    return this.formValorEditar.controls;
  }
  get fValor() {
    return this.formValor.controls;
  }

  onSubmitValor() {
    this.submittedValor = true;  

    if (this.formValor.invalid) {
      return;
    }

    let valor : PrincipioPlantilla = new PrincipioPlantilla();

    valor.idPrincipio = this.idPrincipio;
    valor.nombre = this.fValor.nombre.value

    this.clienteWebService.crearActividadPlantilla(valor).subscribe(respuesta =>{
      this.submittedValor = false;  
      this.fValor.nombre.setValue('');

      if (respuesta.respuesta ==='ok'){
        this.respuestaValor = 'Registro Guardado Exitosamente';
      }else{
        this.respuestaValor = respuesta.respuesta;
      }

      this.obtenerValoresPrincipiosPlantilla(this.idPrincipio);

      this.intervalMensajeActive();

    }, error =>{
      this.respuestaValor = error.error;
      this.submittedValor = false;
      this.intervalMensajeActive();
    });

    
  }


  
  editarValor_(index, valor)
  {
    this.editarValor = index;
    this.fModValor.nombre.setValue(valor);
  }
  
  editarRegla_()
  {
    this.editarRegla = !this.editarRegla;
  }

  modificarValor(index : number)
  {
    this.submittedValorEdit = true;  

    if (this.formValorEditar.invalid) {
      return;
    }

    let principioPlantillaModificado = this.listaValores[index];
    principioPlantillaModificado.nombre = this.fModValor.nombre.value;

    this.clienteWebService.actualizarActividadPlantilla(principioPlantillaModificado).subscribe(respuesta =>{

      this.submittedValorEdit = false;

      if (respuesta.respuesta ==='ok'){
        this.listaValores[index].nombre = this.fModValor.nombre.value;
        this.editarValor=-1;
        this.respuestaValor = 'Registro Actualizado Exitosamente';
      }else{
        this.respuestaValor = respuesta.respuesta;
      }

      this.intervalMensajeActive();

    }, error =>{
      this.respuestaValor = error.error;
      this.submittedValorEdit = false;
      this.intervalMensajeActive();
    });

  }


 
  eliminarValor(index : number, idPrincipioPlantilla: number)
  {

    this.clienteWebService.eliminarActividadPlantilla(idPrincipioPlantilla).subscribe(res =>{
      
      if(res && res.respuesta ==='ok'){
        this.listaValores.splice(index,1);
        this.respuestaValor = 'Registro eliminado correctamente';
      }else{
        this.respuestaValor = res.respuesta;
      }
      this.intervalMensajeActive();
    }, error =>{
      this.respuestaValor = error.error;
      this.intervalMensajeActive();
    })
     
  }

  intervalMensajeActive() {
    const secondsCounter = interval(5000);
    secondsCounter.subscribe(n => {
      this.error = '';
      this.respuesta = '';
      this.errorValor = '';
      this.respuestaValor = '';
      this.submittedValor = false;
    }
    );

    
  }

  logout() {
    this.clienteWebService.logout();
    this.router.navigate(['/login']);
  }

}
