import { PrincipiosInformacion } from "./principios-informacion";

export class Principios {
    nombreUsuario: String;
	codigoEmpleado: String;
	idInstancia: number;
	idPrincipio: number;
	valor: string;
	idPrincipioUsuario: number;
}
