export class RegistroUsuario {
    codigoEmpleado: String;
    nombre: String;
    email: String;
    idDireccion: String;
	error: String;

    public getCodigoEmpleado() {
		return this.codigoEmpleado;
	}
	public setCodigoEmpleado(codigoEmpleado: String) {
		this.codigoEmpleado = codigoEmpleado;
	}
	public getNombre() {
		return this.nombre;
	}
	public setNombre(nombre: String) {
		this.nombre = nombre;
	}
	public getEmail() {
		return this.email;
	}
	public setEmail(email: String) {
		this.email = email;
	}
	public getIdDireccion() {
		return this.idDireccion;
	}
	public setidDireccion(direccion: String) {
		this.idDireccion = direccion;
	}
	public getError() {
		return this.error;
	}
	public setError(error: String) {
		this.error = error;
	}
}
