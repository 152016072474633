import { OnInit, Component, ViewChild, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { ArchivoCsv } from "../clases/archivo-csv";
import { ClienteWebServiceService } from "../services/cliente-web-service.service";

@Component({
    selector: 'app-usuarios-modal',
    templateUrl: './usuarios.error.modal.component.html',
    styleUrls: ['./usuarios.error.modal.component.css']
  })

export class UsuariosErrorModalComponent implements AfterViewInit  {

  mostrarNoHayCsv = false;
  archivosCsv: ArchivoCsv[];

  dataSourceArchivos: MatTableDataSource<ArchivoCsv>;
  displayedColumns: string[] = ["nombre","estado","fecha","numberRegistro","acciones"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private cdref: ChangeDetectorRef ,private clienteWebService: ClienteWebServiceService){

  }

    ngAfterViewInit(): void {
      this.iniciarCsv();
    }

    iniciarCsv(){
        this.mostrarNoHayCsv = false;
        this.archivosCsv = null;    
    
        this.clienteWebService.obtenerCsv().subscribe(res =>{
          if(res){
            this.paginator.pageSize=5;
            this.archivosCsv = res.archivosCsv;
            this.dataSourceArchivos = new MatTableDataSource(this.archivosCsv);
            this.dataSourceArchivos.paginator = this.paginator;
            this.dataSourceArchivos.sort = this.sort;
            this.dataSourceArchivos.paginator.pageSizeOptions=[5,10,15,20];
            this.mostrarNoHayCsv = false;
            this.cdref.detectChanges();
          }else{
            this.mostrarNoHayCsv = true;
          }
          
        },
        error =>{
          this.mostrarNoHayCsv = true;
        });
      }

      descargar(row){
        this.clienteWebService.descargarUsuariosError(`/download/DownloadCsv?idArchivo=${row.idArchivo}`);
      }
}