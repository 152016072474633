import { BrowserModule } from '@angular/platform-browser';
import { NgModule, PipeTransform } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule, MatCheckboxModule,MatTableModule, MatSelectModule,MatInputModule,MatTooltipModule, MatTableDataSource, MatPaginatorModule, MatAutocompleteModule} from '@angular/material';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { RecuperarpasswordComponent } from './recuperarpassword/recuperarpassword.component';
import { JwtInterceptor } from './interceptores/jwt.interceptor.service';
import { HomeComponent } from './home/home.component';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { DatosgeneralesComponent } from './datosgenerales/datosgenerales.component';
import { ValoresComponent } from './valores/valores.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ErrorInterceptorService } from './interceptores/error-interceptor.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ErrorInterceptorMensajeServiceService } from './interceptores/error-interceptor-mensaje-service.service';
import {NgxMaskModule} from 'ngx-mask'
import { UdpCurrencyMaskPipe } from './_guards/udp-currency-mask-pipe';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RECAPTCHA_SETTINGS,RECAPTCHA_LANGUAGE, RecaptchaSettings } from 'ng-recaptcha';
import { UsuariosErrorModalComponent } from './usuarios/usuarios.error.modal.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PlantillavaloresComponent } from './plantillavalores/plantillavalores.component';
import { ObjetivosSComponent } from './objetivos-s/objetivos-s.component';
import { RuedavidaComponent } from './ruedavida/ruedavida.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    ResetpasswordComponent,
    RecuperarpasswordComponent,
    HomeComponent,
    ObjetivosComponent,
    DatosgeneralesComponent,
    ValoresComponent,
    UsuariosComponent,
    UdpCurrencyMaskPipe,
    UsuariosErrorModalComponent,
    PlantillavaloresComponent,
    ObjetivosSComponent,
    RuedavidaComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    NgbAlertModule,
    ChartsModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    PdfViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorMensajeServiceService, multi: true },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LezpbAUAAAAAB1CwQiy6VNSwrch1tdwbEPkQM8c' } as RecaptchaSettings,
    
    },
    UdpCurrencyMaskPipe,
    RuedavidaComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
