import { Component, OnInit, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { UsuarioRegister } from '../clases/usuario-register';
import { Empresa } from '../clases/empresa';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioLogin } from '../clases/usuario-login';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { EmpresasJson } from '../clases/empresas-json';
import { map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { StorageServiceService } from '../services/storage-service.service';
import Swal from 'sweetalert2';
import { UtilServiceService } from '../services/util-service.service';

declare var $: any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  formGrupoUsuario: FormGroup;
  datosUsuario: UsuarioRegister;
  datosUsuarioTmp: UsuarioRegister;
  listaUsuarios: UsuarioRegister[];
  listaUsuariosTm: UsuarioRegister[];
  empresas: Empresa[];
  empresaTmp: Empresa;
  organizaciones: Empresa[];
  direcciones: Empresa[];
  usuario: UsuarioLogin;
  rol: String;
  actiMenu = false;
  submitted = false;
  actualizar = false;
  errorCrearUsuario = '';
  mensajeCrearUsuario = '';
  errorActualizarUsuario = '';
  mensajeActualizarCrearUsuario = '';
  errorBajaUsuario = '';
  mensajeBajaUsuario = '';
  fecha = new Date();
  anio: number;
  busqueda: string = '';
  busqueda2: string = '';

  formGrupoUpload: FormGroup;
  error;
  uploadResponse = { status: '', message: '', porcentaje: '' };

  public bankFilterCtrl: FormControl = new FormControl();


  dataSource: MatTableDataSource<UsuarioRegister>;
  displayedColumns: string[] = ['Nombre', 'Usuario', 'Email', 'Estado', 'Fecha Efectiva', 'Rol', 'Acciones'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(public utilServiceService : UtilServiceService,
    private formBuilder: FormBuilder, private storageService: StorageServiceService,
    private router: Router, public clienteWebService: ClienteWebServiceService,
    private modalService: NgbModal) {
    this.clienteWebService.currentUser.subscribe(x => {
      this.usuario = x;
      if (this.usuario) {
        if(this.usuario.roles !== null){
          this.rol = this.usuario.roles[0];
          if (this.rol === 'USUARIO') {
            this.router.navigate(['/home']);
          }
        }else{
          this.router.navigate(['/home']);
        }
      }
    });
    this.anio = this.storageService.obtenerAnioActual();
    if(this.anio === undefined){
      this.anio = this.fecha.getFullYear();
    }
  }

  ngOnInit() {
    this.iniciarFormaUsuario();
    this.listaUser();
  }

  iniciarFormaUsuario() {
      this.formGrupoUsuario = this.formBuilder.group({
        nombre: new FormControl(this.datosUsuario ? this.datosUsuario.nombre : '', Validators.required),
        email: new FormControl(this.datosUsuario ? this.datosUsuario.email : '', Validators.required),
        codigoEmpleado: new FormControl(this.datosUsuario ? this.datosUsuario.codigoEmpleado : '', Validators.required),
       // direccion: new FormControl({ value: this.datosUsuario ? this.datosUsuario.idDireccion ? this.datosUsuario.idDireccion : '' : '', disabled: true }, Validators.required),
        rol: [this.datosUsuario ? this.datosUsuario.rol : 'USUARIO', Validators.required],
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  listaUser() {
    this.clienteWebService.obtenerUsuarios().subscribe(usuario => {
      //this.listaUsuariosTm = usuario.listaUsuariosR;
      this.listaUsuarios = usuario.listaUsuariosR;

      this.dataSource = new MatTableDataSource(this.listaUsuarios);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


      /*let empr: EmpresasJson = JSON.parse(localStorage.getItem('empresas'));
      if (empr) {
        this.listaUsuariosTm = usuario.listaUsuariosR;
        this.empresas = empr.listaEmpresasJSon;
        this.direcciones = empr.listaEmpresasJSon;
      }*/

    },
      error => {
        console.log("error al consumer el servicio web" + error.message)
      });
  }

  iniciarFormUpload(){
    this.uploadResponse={ status: '', message: '', porcentaje: '' };
    this.formGrupoUpload = this.formBuilder.group({
      file: [''],
      nameFile:['',Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGrupoUsuario.invalid) {
      return;
    }

    this.datosUsuario = new UsuarioRegister();
    this.datosUsuario.nombre = this.f.nombre.value;
    this.datosUsuario.codigoEmpleado = this.f.codigoEmpleado.value;
    this.datosUsuario.email = this.f.email.value;
   // this.datosUsuario.idDireccion = this.f.direccion.value;
    this.datosUsuario.idInstancia = this.usuario.idInstancia;
    this.datosUsuario.fechaEfectiva = this.fecha;
    this.datosUsuario.rol = this.f.rol.value;

    if (this.actualizar) {
      this.datosUsuario.username = this.datosUsuarioTmp.username;
      this.clienteWebService.actualizarUsuario(this.datosUsuario).subscribe(respuesta => {
        if (respuesta.respuesta === 'ok') {
          this.mensajeActualizarCrearUsuario = "Registro Actualizado";
        } else {
          this.errorActualizarUsuario = respuesta.respuesta;
        }
        this.listaUser();
      },
        error => {
          this.errorActualizarUsuario = error.error;
        }
      );
    } else {
      this.datosUsuario.username = this.f.email.value;
      this.clienteWebService.registrarUsuario(this.datosUsuario).subscribe(respuesta => {

        if (respuesta.respuesta === 'ok') {
          this.mensajeCrearUsuario = "Registro Guardado";
          this.modalService.dismissAll();
          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Guardar Usuario',
            text: this.mensajeCrearUsuario,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar'
          });
        } else {
          this.errorCrearUsuario = respuesta.respuesta;
        }
        this.listaUser();
      },
        error => {
          this.errorCrearUsuario = error.error;
        }
      );

    }

  }

  get f() { return this.formGrupoUsuario.controls };

  get fUpload() { return this.formGrupoUpload.controls };

  activarMenu() {
    if (this.actiMenu) {
      this.actiMenu = false;
    } else {
      this.actiMenu = true;
    }

  }

  logout() {
    this.clienteWebService.logout();
    this.router.navigate(['/login']);
  }

  bajaUsuario(usuario: UsuarioRegister) {
    this.clienteWebService.bajaUsuario(usuario).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.mensajeBajaUsuario = "Se dio de Baja al Usuario Exitosamente";
        this.listaUser();
      } else {
        this.errorBajaUsuario = respuesta.respuesta;
      }
      this.intervalMensajeEliminar();
    },
      error => {
        this.errorBajaUsuario = error.error;
        console.log("error al consumer el servicio web de direccion" + error.error)
      });
  }

  resetPasswordPorAdministrador(usuario: UsuarioRegister) {
    this.clienteWebService.resetPasswordPorAdministrador(usuario).subscribe(respuesta => {
      Swal.fire({
        position: 'center',
        type: 'success',
        title: 'Cambio Password',
        text: 'El nuevo password es: '+respuesta.respuesta,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar'
      })
    },
      error => {
        Swal.fire({
          position: 'center',
          type: 'error',
          title: 'ERROR',
          text: 'Ocurrio un error al resetear el password: '+error.error,
          showConfirmButton: true,
          confirmButtonText: 'Aceptar'
        })
      });
  }

  public open(content, usuario: UsuarioRegister) {
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).
      result.then((result) => {
        //this.closeResult = `Closed with: ${result}`;

        console.log(content);
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    if (usuario) {
      this.actualizar = true;
    } else {
      this.actualizar = false;
    }
    this.submitted = false;
    this.mensajeActualizarCrearUsuario = '';
    this.mensajeCrearUsuario = '';
    this.errorActualizarUsuario = '';
    this.errorCrearUsuario = '';
    this.error = '';
    this.formGrupoUsuario = null;
    this.formGrupoUpload = null;
    this.datosUsuario = usuario;
    this.datosUsuarioTmp = this.datosUsuario;
    this.iniciarFormaUsuario();
    this.iniciarFormUpload();
    $(".modal-content").addClass('modal-content-usuario');
  }

  timeSelect() {

    setTimeout(() => {
      var html: HTMLInputElement;
      while (!html) {
        html = (document.getElementById('busquedaId') as HTMLInputElement)
        html.select();
      }
    },
      1000);
}

  iniciarTab() {
    $(".cdk-overlay-container").attr("style", "z-index:2000 !important");
    $(".mat-select-panel").removeAttr('style');
    $(".mat-select-panel").attr('style', "transform-origin: 50% 80.5px 0px; font-size: 14px; min-width: calc(100% + 100px) !important; opacity: 1;transform: scaleY(1);");

  }

  intervalMensajeEliminar() {

    setTimeout(() => {
      this.mensajeBajaUsuario = '';
      this.errorBajaUsuario = '';
    },
      5000);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formGrupoUpload.get('file').setValue(file);
      this.formGrupoUpload.get('nameFile').setValue(file.name);
      this.uploadResponse = { status: '', message: '', porcentaje: '' };
    }
  }

  uploadFile(data){

  return this.clienteWebService.upload(data).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: '', porcentaje: progress};

        case HttpEventType.Response:
          if(event.body.respuesta && event.body.respuesta ==='ok'){
            return { status: 'progress', message: 'Archivo Cargado con Exito', porcentaje: 100};
          }else{
            return event.body;
          }
      }
    })
    )
  }

  onSubmitUpload() {
    this.error='';
    const formData = new FormData();
    formData.append('file', this.formGrupoUpload.get('file').value);

    this.uploadFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res},
      (err) => {
       this.error = err.error}
    );
  }

}
