import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MensajeError } from '../clases/mensaje-error';

@Injectable()
export class ErrorInterceptorMensajeServiceService implements HttpInterceptor {

  mensaje: MensajeError;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {      
      this.mensaje = new MensajeError();

      if(err.status !== 401){
        if (err.status === 0) {
          this.mensaje.error=err.message + err.statusText;
        }else if(err.status === 404){
          this.mensaje.error='No se encontro la pagina: error '+err.status;
        }else if(err.status === 500){
          this.mensaje.error='error interno '+err.status;
        }else{
          this.mensaje.error=err.error
        }     
        return throwError(this.mensaje);
      }else{
        return throwError(err);
      }
  }))
  }

}
