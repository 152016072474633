import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CamposPersonalizados } from '../clases/campos-personalizados';
import { RegExpService } from '../validadores/reg-exp.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class CamposPersonalizadosServiceService {

  constructor() { }
  toFormGroup(campos: CamposPersonalizados[] ) {
    let group: any = {};

    campos.forEach(campo => {
      group[campo.etiqueta] = this.obtenerFormControl(campo);
    });
    return new FormGroup(group);
  }

  obtenerFormControl(campo: CamposPersonalizados): FormControl{
    if(campo.aceptaNull ==='N'){     
      if(campo.tipoValidacion === 'I'){
        return new FormControl(campo.valor || '', [Validators.required,RegExpService(/^[0-9]+/,'enteros'+campo.etiqueta)]);
      }else if (campo.tipoValidacion === 'D') {
          return new FormControl(campo.valor || '', [Validators.required,RegExpService(/^[0-9]+(\.[0-9]+)?$/,'descimales'+campo.etiqueta)]);
      }else if (campo.tipoValidacion === 'E') {
        return new FormControl(campo.valor || '', [Validators.required,Validators.email]);
      }else if (campo.tipoValidacion === 'F') {
        return new FormControl(campo.valor || '', [Validators.required]);
      }else {
        return new FormControl(campo.valor || '', [Validators.required]);
      }

    }else{
      if(campo.tipoValidacion === 'I'){
        return new FormControl(campo.valor || '', [RegExpService(/^[0-9]+/,'enteros'+campo.etiqueta)]);
      }else if (campo.tipoValidacion === 'D') {
          return new FormControl(campo.valor || '', [Validators.required,RegExpService(/^[0-9]+(\.[0-9]+)?$/,'descimales'+campo.etiqueta)]);
      }else if (campo.tipoValidacion === 'E') {
        return new FormControl(campo.valor || '', [Validators.email]);
      }else if (campo.tipoValidacion === 'F') {
        return new FormControl(campo.valor || '', []);
      }else {
        return new FormControl(campo.valor || '', []);
      }
    }
  }


}
