import { Component, OnInit } from '@angular/core';
import { RegistroUsuario } from '../clases/registro-usuario';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';


@Component({
  selector: 'app-root',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  title = 'PlanDeVida';
  usuario: RegistroUsuario; 

  constructor(private cleinte : ClienteWebServiceService) { 
  }

  ngOnInit() {
    this.usuario = new RegistroUsuario();
  }

  submit(){

  }

}
