import { Component, OnInit } from '@angular/core';
import { UsuarioLogin } from '../clases/usuario-login';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { DatosGenerales } from '../clases/datos-generales';
import { CamposPersonalizadosServiceService } from '../services/campos-personalizados-service.service';
import { CamposPersonalizados } from '../clases/campos-personalizados';
import { Empresa } from '../clases/empresa';
import { EmpresasJson } from '../clases/empresas-json';
import { RouterExtService } from '../services/router-ext-service.service';
import { MatTableDataSource, MAT_DRAWER_DEFAULT_AUTOSIZE, MatSlideToggleModule } from '@angular/material';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { StorageServiceService } from '../services/storage-service.service';
import { UtilServiceService } from '../services/util-service.service';


@Component({
  selector: 'datos-generales',
  templateUrl: './datosgenerales.component.html',
  styleUrls: ['./datosgenerales.component.css']
})
export class DatosgeneralesComponent implements OnInit {

  formGroup: FormGroup;
  actiMenu = false;
  mostrarLayoutHome = true;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  respuesta = '';
  datosGenerales: DatosGenerales;
  camposPersonalizadosUsuario: CamposPersonalizados[];
  camposPersonalizadosUsuarioTemp: CamposPersonalizados[] = [];
  today = new Date();
  anio: number;
  nivel1: Empresa[];
  nivel2: Empresa[];
  nivel3: Empresa[];
  nivel4: Empresa[];

  nivel1Storage: Empresa[];
  nivel2Storage: Empresa[];
  nivel3Storage: Empresa[];
  nivel4Storage: Empresa[];

  update = false;
  usuario: UsuarioLogin;

  modalReferenceEmpresa: any;

  busqueda2: string = '';

  hiddeNive2=false;
  hiddeNive3=false;
  hiddeNive4=false;

  mensajeActivarIngresoSimplificado;

  closeResult = '';

  constructor(public utilServiceService : UtilServiceService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router, private clienteWebService: ClienteWebServiceService,
    private formControlPersonalizados: CamposPersonalizadosServiceService,
    private routerExtService: RouterExtService,
    public authenticationService: ClienteWebServiceService,
    private modalService: NgbModal,
    private storageService: StorageServiceService
  ) {
    this.authenticationService.currentUser.subscribe(x =>{
      this.usuario = x;
      if (this.usuario) {
        if(this.usuario.roles === null){
          this.router.navigate(['/home']);
        }
      }
    });
    this.anio = this.storageService.obtenerAnioActual();
    if(this.anio === undefined){
      this.anio = this.today.getFullYear();
    }
  }

  ngOnInit() {

    this.cargarDatos();

  }

  cargarDatos() {
    
    this.clienteWebService.obtenerDatosGenerales().subscribe(datosG => {
      this.datosGenerales = datosG;
      this.camposPersonalizadosUsuario = this.datosGenerales.camposPersonalizadosUsuario;
      let listaEmpresas: Empresa[] = this.datosGenerales.empresas;

      this.nivel1Storage = JSON.parse(localStorage.getItem('nivel1'));
      this.nivel2Storage = JSON.parse(localStorage.getItem('nivel2'));
      this.nivel3Storage= JSON.parse(localStorage.getItem('nivel3'));
      this.nivel4Storage = JSON.parse(localStorage.getItem('nivel4'));

      let direccion: Empresa;
      let gerencia: Empresa;
      let vice: Empresa;
      let empres: Empresa;

      //se ordenan los campos
      if (this.camposPersonalizadosUsuario) {
        this.camposPersonalizadosUsuario = this.camposPersonalizadosUsuario.sort((a, b) => a.orden - b.orden);
      }

      this.nivel1 = this.nivel1Storage;
      this.nivel2 = this.nivel2Storage;
      this.nivel3 = this.nivel3Storage;
      this.nivel4 = this.nivel4Storage;
      
      if(listaEmpresas){
        let lengEmpresas = listaEmpresas.length
        for(let emp of listaEmpresas){
        if(lengEmpresas && lengEmpresas ===4){
            if(emp.nivel === 4){
              empres = emp;
              this.filtrarNivel2(empres.idOrganizacion);
            }else if(emp.nivel === 3){
              vice = emp
              this.filtrarNivel3(vice.idOrganizacion);
            }else if(emp.nivel === 2){
              gerencia = emp
              this.filtrarNivel4(gerencia.idOrganizacion);
            }else if(emp.nivel === 1){
              direccion = emp
            }
        }else if(lengEmpresas && lengEmpresas ===3){
          if(emp.nivel === 3){
            empres = emp;
            this.filtrarNivel2(empres.idOrganizacion);
          }else if(emp.nivel === 2){
            vice = emp
            this.filtrarNivel3(vice.idOrganizacion);
          }else if(emp.nivel === 1){
            gerencia = emp
          }
        }else if(lengEmpresas && lengEmpresas ===2){
          
         if(emp.nivel === 2){
            empres = emp;
            this.filtrarNivel2(empres.idOrganizacion);
          }else if(emp.nivel === 1){
            vice = emp;
          }
        }else if(lengEmpresas && lengEmpresas ===1){
             empres = emp;
             this.filtrarNivel1(empres.idOrganizacion);
           
         }else{
          this.nivel1 = this.nivel1Storage;
          this.nivel2 = this.nivel2Storage;
          this.nivel3 = this.nivel3Storage;
          this.nivel4 = this.nivel4Storage;
        }
      }
    }

     if (this.camposPersonalizadosUsuario) {
        this.formGroup = this.formBuilder.group({
          codigoEmpleado: new FormControl({ value: this.datosGenerales.codigoEmpleado, disabled: true }, Validators.required),
          nombreEmpleado: new FormControl({ value: this.datosGenerales.nombreEmpleado, disabled: true }, Validators.required),
          puesto: [this.datosGenerales.puesto ? this.datosGenerales.puesto : '', Validators.required],
          email: new FormControl({ value: this.datosGenerales.correo, disabled: true }, [Validators.required, Validators.email]),
          ingresoSimplificado: [{value: this.datosGenerales.ingresoDetallado == "S"? false:true,disabled: false }],
          empresa: new FormControl({ value: empres ? empres.idOrganizacion : '', disabled: false }, Validators.required),
          viceprecidencia: new FormControl({ value: vice ? vice.idOrganizacion : '', disabled: false }),
          gerencia: new FormControl({ value: gerencia ? gerencia.idOrganizacion : '', disabled: false }),
          direccion: new FormControl({ value: direccion ? direccion.idOrganizacion : '', disabled: false }),
          idDireccion: new FormControl({ value: this.datosGenerales.idDireccion ? this.datosGenerales.idDireccion : '', disabled: true }, Validators.required),
          camposPersonalizados: this.formControlPersonalizados.toFormGroup(this.camposPersonalizadosUsuario),
          busqueda: ['']
        });
      } else {
        this.formGroup = this.formBuilder.group({
          codigoEmpleado: new FormControl({ value: this.datosGenerales.codigoEmpleado, disabled: true }, Validators.required),
          nombreEmpleado: new FormControl({ value: this.datosGenerales.nombreEmpleado, disabled: true }, Validators.required),
          puesto: [this.datosGenerales.puesto ? this.datosGenerales.puesto : '', Validators.required],
          email: new FormControl({ value: this.datosGenerales.correo, disabled: true }, Validators.required),
          ingresoSimplificado: [{value: this.datosGenerales.ingresoDetallado == "S"? false:true,disabled: true }],
          empresa: new FormControl({ value: empres ? empres.idOrganizacion : '', disabled: false }, Validators.required),
          viceprecidencia: new FormControl({ value: vice ? vice.idOrganizacion : '', disabled: false }),
          gerencia: new FormControl({ value: gerencia ? gerencia.idOrganizacion : '', disabled: false }),
          direccion: new FormControl({ value: direccion ? direccion.idOrganizacion : '', disabled: false }),
          idDireccion: new FormControl({ value: this.datosGenerales.idDireccion ? this.datosGenerales.idDireccion : '', disabled: true }, Validators.required),
          busqueda: ['']
        });
      }

      if (this.datosGenerales.puesto) {
        this.update = true;
      }

      this.clienteWebService.obtenerValorParametro("MS.INGRESO.SIMPLIFICADO").subscribe( p =>{
        this.mensajeActivarIngresoSimplificado = p.valor
      }


      )
    },
      error => {
        this.error = error.error
      });
  }

  /*seleccionarEmpresa(empresa: Empresa) {
    this.f.direccion.setValue(empresa.idOrganizacion);
    this.modalReferenceEmpresa.close();
  }*/

  filtrarNivel1(idOrgnaizacion: number){
    if(!idOrgnaizacion){
      idOrgnaizacion = this.f.empresa.value;
      this.f.empresa.setValue('');
      this.f.viceprecidencia.setValue('');
      this.f.gerencia.setValue('');
      this.f.direccion.setValue('');
    }
    
    this.nivel3 = null;
    this.nivel4 = null;

    if(this.nivel2 && this.nivel2.length>0){
      this.hiddeNive2=false;
    }else{
      this.hiddeNive2=true;
    }

    if(this.nivel3 && this.nivel3.length>0){
      this.hiddeNive3=false;
    }else{
      this.hiddeNive3=true;
    }

    if(this.nivel4 && this.nivel4.length>0){
      this.hiddeNive4=false;
    }else{
      this.hiddeNive4=true;
    }


  }

  filtrarNivel2(idOrgnaizacion: number){

      if(!idOrgnaizacion){
        idOrgnaizacion = this.f.empresa.value;
        this.f.viceprecidencia.setValue('');
        this.f.gerencia.setValue('');
        this.f.direccion.setValue('');
      }

    this.nivel2 = this.nivel2Storage.filter(vic =>{
      return vic.idOrganizacion1 === idOrgnaizacion;
    });

    this.nivel3 = null;
    this.nivel4 = null;

    if(this.nivel3 && this.nivel3.length>0){
      this.hiddeNive3=false;
    }else{
      this.hiddeNive3=true;
    }

    if(this.nivel4 && this.nivel4.length>0){
      this.hiddeNive4=false;
    }else{
      this.hiddeNive4=true;
    }
  }

  filtrarNivel3(idOrgnaizacion: number){

    if(!idOrgnaizacion){
      idOrgnaizacion = this.f.viceprecidencia.value;
      this.f.gerencia.setValue('');
      this.f.direccion.setValue('');
    }

    this.nivel3 = this.nivel3Storage.filter(vic =>{
      return vic.idOrganizacion1 === idOrgnaizacion;
    });

    if(this.nivel3 && this.nivel3.length>0){
      this.hiddeNive3=false;
    }else{
      this.hiddeNive3=true;
    }

    this.nivel4 = null;
  }

  filtrarNivel4(idOrgnaizacion: number){

    if(!idOrgnaizacion){
      idOrgnaizacion = this.f.gerencia.value;
      this.f.direccion.setValue('');
    }

    this.nivel4 = this.nivel4Storage.filter(vic =>{
      return vic.idOrganizacion1 === idOrgnaizacion;
    });

    if(this.nivel4 && this.nivel4.length>0){
      this.hiddeNive4=false;
    }else{
      this.hiddeNive4=true;
    }

  }


  //----------- obtener los controles de la forma
  get camposP() { return this.datosGenerales.camposPersonalizadosUsuario; }

  getCamposPform(campo: string) { return this.f.camposPersonalizados.get(campo) }

  get f() { return this.formGroup.controls; }


  //Metodo que envia los datos
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formGroup.invalid) {
      return;
    }
    this.loading = true;

    let nivel1: number = this.f.empresa.value;
    let nivel2: number = this.f.viceprecidencia.value;
    let nivel3: number = this.f.gerencia.value;
    let nivel4: number = this.f.direccion.value;


    if(nivel1 && nivel2 && nivel3 && nivel4){
      this.datosGenerales.idDireccion = nivel4;
    } else if(nivel1 && nivel2 && nivel3){
      this.datosGenerales.idDireccion = nivel3;
    } else if(nivel1 && nivel2){
      this.datosGenerales.idDireccion = nivel2;
    } else if(nivel1){
      this.datosGenerales.idDireccion = nivel1;
    }

    this.datosGenerales.codigoEmpleado = this.f.codigoEmpleado.value;
    this.datosGenerales.correo = this.f.email.value;
    this.datosGenerales.puesto = this.f.puesto.value;
    if(this.f.ingresoSimplificado.value == false){
      this.datosGenerales.ingresoDetallado = "S";
    }else{
      this.datosGenerales.ingresoDetallado = "N";
    }
    
    
    if (this.camposPersonalizadosUsuario) {
      this.camposPersonalizadosUsuarioTemp = [];
      for (let campoPer of this.camposPersonalizadosUsuario) {
        campoPer.valor = this.getCamposPform(campoPer.etiqueta).value;
        this.camposPersonalizadosUsuarioTemp.push(campoPer);
      }

      this.datosGenerales.camposPersonalizadosUsuario = this.camposPersonalizadosUsuarioTemp;
    }

    if (this.update) {

      this.clienteWebService.actualizarDatosGenerales(this.datosGenerales).subscribe(respuesta => {

        if (respuesta.respuesta === 'ok') {
          this.respuesta = "Registro Actualizado";
          this.usuario.ingresoDetallado = this.datosGenerales.ingresoDetallado
          this.clienteWebService.addUser(this.usuario)
        } else {
          this.error = respuesta.respuesta;
          this.update = false;
        }
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.error = error.message;
        });
    } else {
      this.clienteWebService.registrarDatosGenerales(this.datosGenerales).subscribe(respuesta => {
        if (respuesta.respuesta === 'ok') {
          this.respuesta = "Registro Guardado";
          this.update = true;
          this.usuario.ingresoDetallado = this.datosGenerales.ingresoDetallado
          this.clienteWebService.addUser(this.usuario)
        } else {
          this.error = respuesta.respuesta;
          this.update = false;
        }
        this.loading = false;
      },
        error => {
          this.loading = false;
          this.error = error.message;
        });
    }

    this.submitted = false;
    this.loading = false;
  }

  cancelar() {
    this.f.reset;
    this.router.navigate(["home"]);
  }

  logout() {
    this.clienteWebService.logout();
    this.router.navigate(['/login']);
  }

  activarMenu() {
    if (this.actiMenu) {
      this.actiMenu = false;
    } else {
      this.actiMenu = true;
    }

  }
/*
  public openEmpresa(content) {

    this.modalReferenceEmpresa = this.modalService.open(content);
    this.modalReferenceEmpresa.result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    })
    let idEmp = this.f.direccion.value;

    if (idEmp) {
      let empTmp: Empresa = this.empresas.filter(emp => emp.idOrganizacion === idEmp)[0];
      this.f.busqueda.setValue(empTmp.nombreOrganizacion);
      this.timeSelect();
    }

    // this.iniciarFormaUsuario();

  }*/

  timeSelect() {

    setTimeout(() => {

      var html: HTMLInputElement;
      while (!html) {
        html = (document.getElementById('busquedaId') as HTMLInputElement)
        html.select();
      }
    },
      1000);
  }

  aceptarIngresoSimplificado(){
    //this.f.ingresoSimplificado.setValue(true);
  }

  open(content, env) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.cambiarValorIngresoSimplificado();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.cambiarValorIngresoSimplificado();
      return 'by clicking on a backdrop';
    } else if (reason === 'Cancelar'){
      this.cambiarValorIngresoSimplificado();
    }else if (reason === 'BCerrar'){
      this.cambiarValorIngresoSimplificado();
    }else {
      return `with: ${reason}`;
    }

    
  }

  cambiarValorIngresoSimplificado(){
    var valor = this.f.ingresoSimplificado.value;

    if (valor == true) {
      this.f.ingresoSimplificado.setValue(false);
    }else{
      this.f.ingresoSimplificado.setValue(true);
    }


  }

  

}
