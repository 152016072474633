import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { Router, NavigationEnd } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { UsuarioLogin } from '../clases/usuario-login';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageServiceService } from '../services/storage-service.service';
import { Periodo } from '../clases/periodo';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Empresa } from '../clases/empresa';
import { ParametroReporte } from '../clases/parametro-reporte';
import { ListaParametroReporte } from '../clases/lista-parametro-reporte';
import { UtilServiceService } from '../services/util-service.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  usuario: UsuarioLogin;
  actiMenu = false;
  mostrarLayoutHome = true;
  anioActual: number;
  anioReporte: number;
  mesReporte: number;
  today = new Date();
  datosSrc = { pdfSrc:undefined};
  page: number = 1;
  totalPages: number;
  periodos: Periodo[];
  idPeriodoActual: number;
  periodoActual: Periodo;
  anios: number[];
  meses: number[];
  anioFinPeriodo: number;
  formGroup: FormGroup;

  mensajeReporte='';
  errorReporte='';
  submit: boolean;

  nivel1Storage: Empresa[];
  nivel2Storage: Empresa[];
  nivel3Storage: Empresa[];
  nivel4Storage: Empresa[];

  nivel1: Empresa[];
  nivel2: Empresa[];
  nivel3: Empresa[];
  nivel4: Empresa[];

  hiddeNive2=false;
  hiddeNive3=false;
  hiddeNive4=false;

  submitted = false;
  loading = false;

  reportView = 'reporte';
  tituloPopup = '';

  constructor( public utilServiceService : UtilServiceService, private modalService: NgbModal, private formBuilder: FormBuilder, private router: Router,private httpService: ClienteWebServiceService,private storageService: StorageServiceService,
    public authenticationService: ClienteWebServiceService,public sanitizer: DomSanitizer) {
    this.authenticationService.currentUser.subscribe(x => {this.usuario = x
      this.anioActual = this.storageService.obtenerAnioActual();
      this.obtenerPeriodos();
    });    
   }

  ngOnInit() {

    this.viewPdf();

    this.cargarDatos();
    $(document).ready(function () {
      "use strict"; // Start of use strict
      // Add smooth scrolling on all links inside the navbar
      $(".js-scroll-trigger").on('click', function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          // Prevent default anchor click behavior
          event.preventDefault();

          // Store hash
          var hash = this.hash;

          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 800, function () {

            // Add hash (#) to URL when done scrolling (default click behavior)
           // window.location.hash = hash;
          });
        }  // End if
      });
    });
   
  }

  cargarDatos() {
    this.httpService.obtenerPeriodos().subscribe(periodo => {
      this.periodos = periodo.periodos;
      this.anioActual = periodo.anioActual;

      if (this.idPeriodoActual === undefined) {
        this.idPeriodoActual = periodo.idPeriodoActual;
      }

      this.nivel1Storage = JSON.parse(localStorage.getItem('nivel1'));
      this.nivel2Storage = JSON.parse(localStorage.getItem('nivel2'));
      this.nivel3Storage= JSON.parse(localStorage.getItem('nivel3'));
      this.nivel4Storage = JSON.parse(localStorage.getItem('nivel4'));

      this.nivel1 = this.nivel1Storage;
      this.nivel2 = this.nivel2Storage;
      this.nivel3 = this.nivel3Storage;
      this.nivel4 = this.nivel4Storage;

      this.hiddeNive2=true;
      this.hiddeNive3=true;
      this.hiddeNive4=true;

      //Valida que tenga un periodo ingresado
      if (this.idPeriodoActual) {
        this.periodoActual = this.periodos.filter(periodo => { return periodo.idPeriodo = this.idPeriodoActual })[0];
        this.anios = [];
        this.meses = [];
        this.anioFinPeriodo = (this.periodoActual.inicio + this.periodoActual.duracion)-1;

        for (let a = this.periodoActual.inicio; a < (this.periodoActual.inicio + this.periodoActual.duracion); a++) {
              this.anios.push(a);          
        }

        for (let a = 1; a <= 12; a++) {
          this.meses.push(a);          
        }
      }

      this.builderForm();
       
    },
      error => {
        console.log(error);
      });
  }

  builderForm(){
    if(this.reportView == 'reporteA'){
      //Se crea la forma
      this.formGroup = this.formBuilder.group({
        periodo: new FormControl({value:  '', disabled: false}, Validators.required),
        anio: new FormControl({value: this.anioReporte ? this.anioReporte : '' , disabled: false}, Validators.required),
        mes: new FormControl({value: this.mesReporte ? this.mesReporte:'' , disabled: false}, Validators.required)
      });
    }else if(this.reportView == 'reporteOr'){
      this.formGroup = this.formBuilder.group({
        empresa: new FormControl({ value: '', disabled: false }, Validators.required),
        viceprecidencia: new FormControl({ value: '', disabled: false }),
        gerencia: new FormControl({ value: '', disabled: false }),
        direccion: new FormControl({ value: '', disabled: false }),
        periodo: new FormControl({value:  '', disabled: false}, Validators.required),
        anio: new FormControl({value: this.anioReporte ? this.anioReporte : '' , disabled: false}, Validators.required)
      });
    }else{
      this.formGroup = this.formBuilder.group({
        periodo: new FormControl({value:  '', disabled: false}, Validators.required),
        anio: new FormControl({value: this.anioReporte ? this.anioReporte : '' , disabled: false}, Validators.required)      });
    }
  }

  get f() {
    return this.formGroup.controls;
  }

  filtrarNivel2(idOrgnaizacion: number){

      if(!idOrgnaizacion){
        idOrgnaizacion = this.f.empresa.value;
        this.f.viceprecidencia.setValue('');
        this.f.gerencia.setValue('');
        this.f.direccion.setValue('');
      }

    this.nivel2 = this.nivel2Storage.filter(vic =>{
      return vic.idOrganizacion1 === idOrgnaizacion;
    });

    this.nivel3 = null;
    this.nivel4 = null;

    if(this.nivel2 && this.nivel2.length>0){
      this.hiddeNive2=false;
    }else{
      this.hiddeNive2=true;
    }

    if(this.nivel3 && this.nivel3.length>0){
      this.hiddeNive3=false;
    }else{
      this.hiddeNive3=true;
    }

    if(this.nivel4 && this.nivel4.length>0){
      this.hiddeNive4=false;
    }else{
      this.hiddeNive4=true;
    }
  }

  filtrarNivel3(idOrgnaizacion: number){

    if(!idOrgnaizacion){
      idOrgnaizacion = this.f.viceprecidencia.value;
      this.f.gerencia.setValue('');
      this.f.direccion.setValue('');
    }

    this.nivel3 = this.nivel3Storage.filter(vic =>{
      return vic.idOrganizacion1 === idOrgnaizacion;
    });

    if(this.nivel3 && this.nivel3.length>0){
      this.hiddeNive3=false;
    }else{
      this.hiddeNive3=true;
    }

    this.nivel4 = null;
  }

  filtrarNivel4(idOrgnaizacion: number){

    if(!idOrgnaizacion){
      idOrgnaizacion = this.f.gerencia.value;
      this.f.direccion.setValue('');
    }

    this.nivel4 = this.nivel4Storage.filter(vic =>{
      return vic.idOrganizacion1 === idOrgnaizacion;
    });

    if(this.nivel4 && this.nivel4.length>0){
      this.hiddeNive4=false;
    }else{
      this.hiddeNive4=true;
    }

  }

  descargarReporte(){
    this.submitted = true;
    // stop here if form is invalid

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;    
    this.anioReporte = this.f.anio.value;   

    let listaParametroReporte: ListaParametroReporte = new ListaParametroReporte();
    let listaParameteros: ParametroReporte[] = new Array<ParametroReporte>();
    let parametroReporte: ParametroReporte = new ParametroReporte();

    if(this.reportView == 'reporteOr' || this.reportView == 'reporteG'){

      let idDireccion: number;

      if(this.reportView == 'reporteOr'){

        let nivel1: number = this.f.empresa.value;
        let nivel2: number = this.f.viceprecidencia.value;
        let nivel3: number = this.f.gerencia.value;
        let nivel4: number = this.f.direccion.value;

        if(nivel1 && nivel2 && nivel3 && nivel4){
          idDireccion = nivel4;
        } else if(nivel1 && nivel2 && nivel3){
          idDireccion = nivel3;
        } else if(nivel1 && nivel2){
          idDireccion = nivel2;
        } else if(nivel1){
          idDireccion = nivel1;
        }
      }else{
        idDireccion=1;
      }

      parametroReporte.nombreParametro = 'ANIO';
      parametroReporte.valor = this.anioReporte+'';
      listaParameteros.push(parametroReporte);

      parametroReporte = new ParametroReporte();
      parametroReporte.nombreParametro = 'IDDIRECCION';
      parametroReporte.valor = idDireccion+'';
      listaParameteros.push(parametroReporte); 

      listaParametroReporte.dataSource = 'REPORTEPLAN';
      listaParametroReporte.nombreReprote = 'ReporteControlPlanPadre2';
      listaParametroReporte.listaParameteros = listaParameteros;

    }else{

      this.mesReporte = this.f.mes.value;
      parametroReporte.nombreParametro = 'ANIO';
      parametroReporte.valor = this.anioReporte+'';
      listaParameteros.push(parametroReporte);

      parametroReporte = new ParametroReporte();
      parametroReporte.nombreParametro = 'MES';
      parametroReporte.valor = this.mesReporte+'';
      listaParameteros.push(parametroReporte); 

      listaParametroReporte.dataSource = 'REPORTEPLAN';
      listaParametroReporte.nombreReprote = 'ReporteUsuariosDeAlta';
      listaParametroReporte.listaParameteros = listaParameteros;

    }

    this.httpService.descargarReporteUrl(listaParametroReporte).subscribe(async respuesta => {
      await this.httpService.descargarPlan(respuesta.respuesta);
      this.mensajeReporte = 'Reporte Generado';
      this.submitted = false;
      this.loading = false;

    }, error => {
      this.errorReporte = error.error;
      this.submitted = false;
      this.loading = false;
    });

  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  activarMenu() {
    if (this.actiMenu) {
      this.actiMenu = false;
    } else {
      this.actiMenu = true;
    }

  }

   descargarGenerica(){
   this.authenticationService.descargarGenericaPdf(this.datosSrc,'download/manual.pdf', function(pdfSrc,statuses){
    pdfSrc = statuses;
  });   
  }

  viewPdf(){
    this.authenticationService.viewPdf(this.datosSrc,'download/manual.pdf', function(pdfSrc,statuses){
     pdfSrc = statuses;
   });   
   }

   afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  onChange(periodo) {
    this.periodoActual = periodo;
    this.anios = [];
    for (let a = this.periodoActual.inicio; a < (this.periodoActual.inicio + this.periodoActual.duracion); a++) {
          this.anios.push(a);
    }
  }

//Primera carga
  obtenerPeriodos() {
    if (this.anioActual === undefined && this.usuario || this.anioActual===0 && this.usuario){
        this.httpService.obtenerPeriodos().subscribe(periodo => {

          if(periodo){
          this.storageService.guardarAnioActual(periodo.anioActual);
          this.anioActual = periodo.anioActual;
          }else{
            this.anioActual = this.today.getFullYear();
            this.storageService.guardarAnioActual(this.anioActual);
          }
        },
          error => {
            console.log("Error Obtener Periodo");
        });
    }
  } 

  intervalMensajeActivo() {
    this.mensajeReporte='';
    this.errorReporte='';
    this.submit=false;
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    this.reportView = 'reporteA';
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    this.reportView = 'reporteA';
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

public open(content, reporte, tituloPopup) {
  this.submit = false;
  this.mensajeReporte = '';
  this.errorReporte = '';
  this.reportView = reporte;
  this.formGroup = null;
  this.hiddeNive2 = true;
  this.hiddeNive3 = true;
  this.hiddeNive4 = true;
  this.anioReporte = null;
  this.mesReporte = null;
  this.builderForm();
  this.tituloPopup = tituloPopup;
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).
    result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

}

