export class UsuarioRegister {
    codigoEmpleado: string;
	nombre: string;
	email: string;
    idDireccion: number;    
	rol: string;
	error: string;
	fechaEfectiva: Date;
	idInstancia: number;
	idOrganizacion: number;
	idEmpresa: number;
	username: string;
}
