import { Component, OnInit } from '@angular/core';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { StorageServiceService } from '../services/storage-service.service';
import { UsuarioLogin } from '../clases/usuario-login';
import { UtilServiceService } from '../services/util-service.service';

@Component({
  selector: 'app-ruedavida',
  templateUrl: './ruedavida.component.html',
  styleUrls: ['./ruedavida.component.css']
})
export class RuedavidaComponent implements OnInit {

  chartLabels:any;
  chartData: any;
  respuesta: any;
  objetivos:string[];

  anioActual: number;
  usuario: UsuarioLogin;
  today = new Date();

  chartOptions = {
    responsive: true,
    scale: {
      ticks: {
          beginAtZero: true,
          max: 11,
          min: 0
         
      }
  },
  tooltips: {
    enabled: false,
    }
  };

  constructor(public authenticationService: ClienteWebServiceService,
    private storageService: StorageServiceService,
    private httpService: ClienteWebServiceService,
    public utilServiceService : UtilServiceService) {

    this.authenticationService.currentUser.subscribe(x => {this.usuario = x
      this.anioActual = this.storageService.obtenerAnioActual();
      this.obtenerPeriodos();
    }); 

   }

  ngOnInit() {
    this.authenticationService.obtenerDatosGrafica().subscribe(resultado =>{
      this.respuesta = resultado;
      
      if(this.respuesta){
        this.chartData = resultado.datos;
        this. chartLabels = resultado.chartLabels;

        if(resultado.datosYaIngresados){
        this.objetivos = [];
        for(let label of resultado.datosYaIngresados){
          var key=label.replace(/\s/g, '');
          this.objetivos[key]=label;
        }  
      }      
      }
    });

  }

  obtenerPeriodos() {
    if (this.anioActual === undefined && this.usuario || this.anioActual===0 && this.usuario){
        this.httpService.obtenerPeriodos().subscribe(periodo => {

          if(periodo){
          this.storageService.guardarAnioActual(periodo.anioActual);
          this.anioActual = periodo.anioActual;
          }else{
            this.anioActual = this.today.getFullYear();
            this.storageService.guardarAnioActual(this.anioActual);
          }
        },
          error => {
            console.log("Error Obtener Periodo");
        });
    }
  } 

  onChartClick(event) {
    
  }

}
