import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsuarioLogin } from '../clases/usuario-login';
import { DatosGenerales } from '../clases/datos-generales';
import { Empresa } from '../clases/empresa';
import { EmpresasJson } from '../clases/empresas-json';
import { Principios } from '../clases/principios';
import { PrincipioData } from '../clases/principios-data';
import { ListaPeriodo } from '../clases/lista-periodo';
import { Plan } from '../clases/plan';
import { ListaObjetivosIngresados } from '../clases/lista-objetivos-ingresados';
import { ActividadObjetivo } from '../clases/actividad-objetivo';
import { ListaActividadObjetivo } from '../clases/lista-actividad-objetivo';
import { ListaUsuarios } from '../clases/lista-usuarios';
import { UsuarioRegister } from '../clases/usuario-register';
import { ListaArchivosCsv } from '../clases/lista-archivos-csv';
import { saveAs } from 'file-saver';
import { ListaParametroReporte } from '../clases/lista-parametro-reporte';
import { Parametro } from '../clases/parametro';
import { PrincipioPlantilla } from '../clases/prinpicio-plantilla';
import { ListaPrincipioPlantilla } from '../clases/lista-principio-plantilla';
import { ListaPrincipiosUsuario } from '../clases/lista-principios-usuario';

@Injectable({
  providedIn: 'root'
})
export class ClienteWebServiceService {
  private currentUserSubject: BehaviorSubject<UsuarioLogin>;
  public currentUser: Observable<UsuarioLogin>;

  public titulo="PLAN PERSONAL";
  public subTitulo="DE VIDA";

  public urlRet = "/PlanDeVida/rest";
  public urlRet2 = "/PlanDeVida";
  //public urlRet = "http://localhost:8080/PlanDeVida/rest";
  //public urlRet2 = "http://localhost:8080/PlanDeVida";

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UsuarioLogin>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UsuarioLogin {
    return this.currentUserSubject.value;
  }

  addUser(usuario: any) {
    this.currentUserSubject.next(usuario);
    this.currentUser = this.currentUserSubject.asObservable();
    localStorage.setItem('currentUser', JSON.stringify(usuario));
  }

  login(nombreUsuario: String, password: String) {
    var url = this.urlRet + "/autenticacion/usuario/login";

    return this.http.post<any>(url, { nombreUsuario, password })
      .pipe(map(user => {
        
        // login successful if there's a jwt token in the response
        if (user && user.token) {

          // store user details and jwt token in local storage to keep user logged in between page refreshes
         localStorage.setItem('currentUser', JSON.stringify(user));
         this.currentUserSubject.next(user);

          //Obtiene empresa y se almacena en memoria
          this.obtenerEmpresas().subscribe(empresas => {
            if (empresas) {
              let nivel1: Empresa[];
              let nivel2: Empresa[];
              let nivel3: Empresa[];
              let nivel4: Empresa[];

              nivel1 = empresas.listaEmpresasJSon.filter(empresa => { return empresa.nivel === 2 });
              nivel2 = empresas.listaEmpresasJSon.filter(empresa => { return empresa.nivel === 3 });
              nivel3 = empresas.listaEmpresasJSon.filter(empresa => { return empresa.nivel === 4 });
              nivel4 = empresas.listaEmpresasJSon.filter(empresa => { return empresa.nivel === 5 });

              localStorage.setItem('nivel1', JSON.stringify(nivel1));
              localStorage.setItem('nivel2', JSON.stringify(nivel2));
              localStorage.setItem('nivel3', JSON.stringify(nivel3));
              localStorage.setItem('nivel4', JSON.stringify(nivel4));
            }
          },
            error => {
              console.log("error al consumer el servicio web" + error.message)
            });

        }
        return user;
      }));
  }

  resetPassword(usuarioLogin: UsuarioLogin) {
    var url = this.urlRet + "/PlanDeVida/resetearPassword";

    return this.http.put<any>(url, usuarioLogin)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {


          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);

        }
        return user;
      }));
  }

  recuperarPassword(email: String, codigoEmpleado: String) {
    var url = this.urlRet + "/autenticacion/usuario/recuperarPassword";

    return this.http.put<any>(url, { email, codigoEmpleado })
      .pipe(map(respuesta => {
        // login successful if there's a jwt token in the response
        return respuesta;
      }));
  }

  obtenerDatosGenerales() {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);

    let nombreUsuario = user.nombreUsuario;
    let codigoEmpleado = user.codigoEmpleado;
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerDatosGenerales/" + nombreUsuario + "/" + codigoEmpleado + "/" + idInstancia;
    return this.http.get<DatosGenerales>(url);
  }

  obtenerValorParametro(nombreParametro: String)  {
    var url = this.urlRet + "/PlanDeVida/valorParametro/"+nombreParametro;
    return this.http.get<Parametro>(url);
  }

  obtenerUsuarios() {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerListaUsuariosInstancia/" + idInstancia;
    return this.http.get<ListaUsuarios>(url);
  }

  obtenerCsv() {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerArchivosCsv/" + idInstancia;
    return this.http.get<ListaArchivosCsv>(url);
  }

  registrarDatosGenerales(datosGenerales: DatosGenerales) {
    var url = this.urlRet + "/PlanDeVida/RegistrarDatosGenerales";
    return this.http.post<any>(url, datosGenerales);
  }

  registrarUsuario(usuario: UsuarioRegister) {
    var url = this.urlRet + "/PlanDeVida/RegistrarUsuario";
    return this.http.post<any>(url, usuario);
  }

  bajaUsuario(usuario: UsuarioRegister) {
    var url = this.urlRet + "/PlanDeVida/UsuarioDarBaja";
    return this.http.post<any>(url, usuario);
  }

  resetPasswordPorAdministrador(usuario: UsuarioRegister) {
    var url = this.urlRet + "/PlanDeVida/resetearPasswordPorAdministrador";
    return this.http.put<any>(url, usuario);
  }

  actualizarUsuario(usuario: UsuarioRegister) {
    var url = this.urlRet + "/PlanDeVida/ActualizarUsuario";
    return this.http.post<any>(url, usuario);
  }

  actualizarDatosGenerales(datosGenerales: DatosGenerales) {
    var url = this.urlRet + "/PlanDeVida/ActualizarDatosGenerales";
    return this.http.post<any>(url, datosGenerales);
  }

  registrarValores(principios: Principios) {
    var url = this.urlRet + "/PlanDeVida/GuardarPrincipiosJSon";
    return this.http.post<any>(url, principios);
  }

  actualizarValores(principios: Principios) {
    var url = this.urlRet + "/PlanDeVida/ActualizarPrincipiosJSon";
    return this.http.post<any>(url, principios);
  }

  eliminarPrincipio(principios: Principios) {
    var url = this.urlRet + "/PlanDeVida/EliminarPrincipiosJSon";
    return this.http.post<any>(url, principios);
  }

  registrarPlanObjetivo(plan: Plan) {
    var url = this.urlRet + "/PlanDeVida/GuardarPlanObjetivo";
    return this.http.post<any>(url, plan);
  }

  actualizarPlanObjetivo(plan: Plan) {
    var url = this.urlRet + "/PlanDeVida/ActualizarPlanObjetivo";
    return this.http.post<any>(url, plan);
  }

  activarPlan(plan: Plan) {
    var url = this.urlRet + "/PlanDeVida/ActivarPlan";
    return this.http.post<any>(url, plan);
  }

  enviarPlan(plan: Plan) {
    var url = this.urlRet + "/PlanDeVida/EnviarPlan";
    return this.http.post<any>(url, plan);
  }

  descargarPlanUrl(plan: Plan) {
    var url = this.urlRet + "/PlanDeVida/DescargarPlan";
    return this.http.post<any>(url, plan);
  }

  descargarReporteUrl(listaParametroReporte: ListaParametroReporte) {
    var url = this.urlRet + "/PlanDeVida/GenerarReporte";
    return this.http.post<any>(url, listaParametroReporte);
  }

  descargarPlan(url: string) {
    var url = this.urlRet2 + `/download/DownloadPdf?url=${url}`;

    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    var oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.setRequestHeader('Authorization', 'Bearer '+user.token) 
    oReq.responseType = "blob";   

    oReq.onreadystatechange = function (oEvent){
      if (oReq.readyState === 4) { 
        
        if (oReq.status === 200) { 
          oReq.onload = function() {
            var file = new Blob([oReq.response], { 
                type: 'application/pdf' 
            });            
            saveAs(file, "plan_de_vida.pdf");
          };
  
          //oReq.send(); 
        } else { 
          alert("Error "+oReq.statusText+": No se puede descargar el archivo");
        } 
      }
    } 
        oReq.send();       
  }

  descargarUsuariosError(url: string) {
    var url = this.urlRet2 + url;
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    var oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.setRequestHeader('Authorization', 'Bearer '+user.token) 
    oReq.responseType = "blob";   

    oReq.onreadystatechange = function (oEvent){
      if (oReq.readyState === 4) { 
        
        if (oReq.status === 200) { 
          oReq.onload = function() {
            var file = new Blob([oReq.response], { 
                type: 'application/csv' 
            });            
            saveAs(file, "plan_de_vida.csv");
          };
  
          //oReq.send(); 
        } else { 
          alert("Error "+oReq.statusText+": No se puede descargar el archivo");
        } 
      }
    } 
        oReq.send();       
  }

  descargarGenericaPdf(variable, url: string, callback){
    var url = this.urlRet2 +'/'+ url;

    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    var oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.setRequestHeader('Authorization', 'Bearer '+user.token) 
    oReq.setRequestHeader("Content-type", "application/pdf");
    oReq.responseType = "arraybuffer";   
    oReq.onreadystatechange = function (oEvent){
      if (oReq.readyState === 4) { 
        
        if (oReq.status === 200) { 
          oReq.onload = function() {
            var file = new Blob([oReq.response], { 
                type: 'application/pdf' 
            }); 
            saveAs(file, "plan_de_vida.pdf");
            var url2= window.URL.createObjectURL(file);
              //let pwa = window.open(url2, '_blank');
              variable.pdfSrc=url2;              
            /*  if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
                alert( 'Please disable your Pop-up blocker and try again.');
            } */
          };
  
          //oReq.send(); 
        } else { 
          alert("Error "+oReq.statusText+": No se puede descargar el archivo");
        } 
      }
    } 

    oReq.send();              
  }
  

  viewPdf(variable, url: string, callback){
    var url = this.urlRet2 +'/'+ url;

    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    var oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.setRequestHeader('Authorization', 'Bearer '+user.token) 
    oReq.setRequestHeader("Content-type", "application/pdf");
    oReq.responseType = "arraybuffer";   
    oReq.onreadystatechange = function (oEvent){
      if (oReq.readyState === 4) { 
        
        if (oReq.status === 200) { 
          oReq.onload = function() {
            var file = new Blob([oReq.response], { 
                type: 'application/pdf' 
            }); 
            
            var url2= window.URL.createObjectURL(file);
              variable.pdfSrc=url2;              
          };
  
          //oReq.send(); 
        } else { 
          console.error("Error "+oReq.statusText+": No se puede descargar el archivo");
        } 
      }
    } 

    oReq.send();              
  }

  cerrarPlan(plan: Plan) {
    var url = this.urlRet + "/PlanDeVida/CerrarPlan";
    return this.http.put<any>(url, plan);
  }

  registrarActividad(actividadObjetivo: ActividadObjetivo) {
    var url = this.urlRet + "/PlanDeVida/GuardarActividadObjetivo";
    return this.http.post<any>(url, actividadObjetivo);
  }

  actualizarActividad(actividadObjetivo: ActividadObjetivo) {
    var url = this.urlRet + "/PlanDeVida/ActualizarActividadObjetivo";
    return this.http.post<any>(url, actividadObjetivo);
  }

  eliminarActividad(actividadObjetivo: ActividadObjetivo) {
    var url = this.urlRet + "/PlanDeVida/EliminarActividadObjetivo";
    return this.http.post<any>(url, actividadObjetivo);
  }

  obtenerActividadesTrimestrales(idObjetivoUsuario: number) {
    var url = this.urlRet + "/PlanDeVida/obtenerActividadesTrimestrales/" + idObjetivoUsuario;
    return this.http.get<ListaActividadObjetivo>(url);
  }

  obtenerActividadesEntregables(idObjetivoUsuario: number, idActividad: number) {
    var url = this.urlRet + "/PlanDeVida/obtenerActividadesEntregable/" + idObjetivoUsuario + "/" + idActividad;
    return this.http.get<ListaActividadObjetivo>(url);
  }

  obtenerEmpresas() {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);

    let idInstancia = user.idInstancia;
    var url = this.urlRet + "/PlanDeVida/obtenerEmpresas/" + idInstancia;
    return this.http.get<EmpresasJson>(url);
  }

  obtenerEmpresa(idEmpresa: number) {
    var url = this.urlRet + "/PlanDeVida/obtenerEmpresa/" + idEmpresa;
    return this.http.get<Empresa>(url);
  }

  obtenerEmpresasHijas(idEmpresaPadre: number) {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerEmpresasHijas/" + idInstancia + "/" + idEmpresaPadre;

    return this.http.get<EmpresasJson>(url);
  }

  obtenerPrincipios() {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    let nombreUsuario = user.nombreUsuario;
    let codigoEmpleado = user.codigoEmpleado;

    var url = this.urlRet + "/PlanDeVida/obtenerPrincipios/" + nombreUsuario + "/" + codigoEmpleado;

    return this.http.get<PrincipioData>(url);
  }

  obtenerDatosGrafica() {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    let nombreUsuario = user.nombreUsuario;
    let today = new Date();
    var anio: number = today.getFullYear();
    var url = this.urlRet + "/PlanDeVida/obtenerDatosGraficas/" + nombreUsuario + "/" + anio;

    return this.http.get<any>(url);
  }

  obtenerDatosGraficaOtrosAños(anio) {
    let user: UsuarioLogin;
    this.currentUser.subscribe(x => user = x);
    let nombreUsuario = user.nombreUsuario;
    var url = this.urlRet + "/PlanDeVida/obtenerDatosGraficasOtrosAnios/" + nombreUsuario + "/" + anio;

    return this.http.get<any>(url);
  }

  obtenerPeriodos() {
    let user: UsuarioLogin = JSON.parse(localStorage.getItem('currentUser'));
    let nombreUsuario = user.nombreUsuario;
    let codigoEmpleado = user.codigoEmpleado;
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerPeriodos/" + idInstancia + "/" + nombreUsuario + "/" + codigoEmpleado;

    return this.http.get<ListaPeriodo>(url);
  }

  obtenerEstadoAnio(anio: number, idPeriodo: number) {
    let user: UsuarioLogin = JSON.parse(localStorage.getItem('currentUser'));
    let nombreUsuario = user.nombreUsuario;
    let codigoEmpleado = user.codigoEmpleado;

    var url = this.urlRet + "/PlanDeVida/obtenerEstadoAnio/" + anio + "/" + idPeriodo + "/" + nombreUsuario + "/" + codigoEmpleado;

    return this.http.get<any>(url);
  }

  obtenerObjetivosYaIngresados(anio: number, periodo: number) {
    let user: UsuarioLogin = JSON.parse(localStorage.getItem('currentUser'));
    let nombreUsuario = user.nombreUsuario;
    let codigoEmpleado = user.codigoEmpleado;
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerObjetivoIngresado/" + nombreUsuario + "/" + codigoEmpleado + "/" + anio + "/" + periodo + "/" + idInstancia;

    return this.http.get<ListaObjetivosIngresados>(url);
  }

  obtenerInformacionDeObjetivo(anio: number, periodo: number, idObjetivo: number) {
    let user: UsuarioLogin = JSON.parse(localStorage.getItem('currentUser'));
    let nombreUsuario = user.nombreUsuario;
    let codigoEmpleado = user.codigoEmpleado;
    let idInstancia = user.idInstancia;

    var url = this.urlRet + "/PlanDeVida/obtenerPlanObjetivo/" + nombreUsuario + "/" + codigoEmpleado + "/" + anio + "/" + periodo + "/" + idInstancia + "/" + idObjetivo;

    return this.http.get<Plan>(url);
  }

  obtenerInformacionDeActividad(idObjetivoUsuario: number, idActividad: number) {

    var url = this.urlRet + "/PlanDeVida/obtenerActividadIngresado/" + idObjetivoUsuario + "/" + idActividad;

    return this.http.get<ActividadObjetivo>(url);
  }

  obtenerListaActividadPlantilla(idPrincipio) {
    var url = this.urlRet + `/PlanDeVida/obtenerListaPrincipioPlantilla/${idPrincipio}`;
    return this.http.get<ListaPrincipioPlantilla>(url);
  }

  crearActividadPlantilla(principioPlantilla: PrincipioPlantilla) {
    var url = this.urlRet + "/PlanDeVida/crearPrincipioPlantilla";
    return this.http.post<any>(url, principioPlantilla);
  }

  actualizarActividadPlantilla(principioPlantilla: PrincipioPlantilla) {
    var url = this.urlRet + "/PlanDeVida/actualizarPrincipioPlantilla";
    return this.http.put<any>(url, principioPlantilla);
  }

  eliminarActividadPlantilla(idPrincipioPlantilla: number) {
    var url = this.urlRet + `/PlanDeVida/eliminarPrincipioPlantilla/${idPrincipioPlantilla}`;
    return this.http.delete<any>(url);
  }

  registrarListaValoresYReglas(listaPrincipiosUsuario: ListaPrincipiosUsuario) {
    var url = this.urlRet + "/PlanDeVida/GuardarListaPrincipiosUsuarioJSon";
    return this.http.post<any>(url, listaPrincipiosUsuario);
  }

  public upload(data) {
    let user: UsuarioLogin = JSON.parse(localStorage.getItem('currentUser'));
    let idInstancia = user.idInstancia;

    var url = `${this.urlRet}/PlanDeVida/uploadFile/${idInstancia}`;

    return this.http.post<any>(url, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  logout() {
    // remove user from local storage to log user out    
    this.currentUserSubject.next(null);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('empresas');
    localStorage.removeItem('vicepresidencia');
    localStorage.removeItem('gerencia');
    localStorage.removeItem('direccion');
    localStorage.removeItem('periodoActual');
    localStorage.removeItem('anioActual');
  }
}
