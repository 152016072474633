import { Injectable } from '@angular/core';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private authenticationService: ClienteWebServiceService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

    
  return next.handle(request)
	    .pipe(
	        tap(event => {
	          if (event instanceof HttpResponse) {

               let tokenNew = event.headers.get('Authorization');
               let currentUser = this.authenticationService.currentUserValue;
               if(tokenNew && currentUser){
                  currentUser.token = tokenNew;
                  this.authenticationService.addUser(currentUser);
               }
            }
	        }, errorHttp => {
	   			
           if (errorHttp.status === 401) {
            this.authenticationService.logout();
            location.reload(true);
          }
          return throwError(errorHttp);

	        })
	      );
  }
}
