import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UsuarioLogin } from '../clases/usuario-login';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { first } from 'rxjs/operators';
import * as $ from "jquery";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  usuario: UsuarioLogin;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,  public authenticationService : ClienteWebServiceService) { }

  ngOnInit() {
    this.authenticationService.logout();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      recaptchaReactive: new FormControl(null, Validators.required)
    });    
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.error = '';
    this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
            data => {

                this.usuario = data;
                //Se verifica si hay que resetear el password
                if(this.usuario.cambiarPassword ==="T"){
                    this.router.navigate(["resetearPassword"]);
                }else{
                      this.router.navigate(["home"]);
                }
            },
            error => {
                this.error = error.error;                
                this.loading = false;
                
            });
    }

}
