import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { first } from 'rxjs/operators';
import { UsuarioLogin } from '../clases/usuario-login';

@Component({
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  usuario: UsuarioLogin;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  passwrodDiferentes = false;
  verPassword = false;
  verPassword2 = false;
  msPasswordExpirde='';

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private clienteWebService: ClienteWebServiceService, private router: Router, 
    public authenticationService : ClienteWebServiceService) {

      this.authenticationService.currentUser.subscribe(x => this.usuario = x);
      this.returnUrl = this.route.snapshot.queryParams['url'] || '/login';

      this.clienteWebService.obtenerValorParametro("MS.PASSWORD.EXPIRED").subscribe( p =>{
        this.msPasswordExpirde = p.valor
      });
    }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      codigoEmpleado: [{value: this.usuario?this.usuario.codigoEmpleado?this.usuario.codigoEmpleado:'':'', disabled: true}, Validators.required],
      password: ['',[Validators.required,Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-\+\=\(\)\?])(?=.{8,})/)]],
      confirmPass: ['', Validators.required]
    },{validator: this.checkPasswords });
  }

  get f() { return this.resetPasswordForm.controls; }

  onVerPassword(){
    if(this.verPassword){
      this.verPassword = false;
    }else{
      this.verPassword = true;
    }
  }

  onVerPassword2(){
    if(this.verPassword2){
      this.verPassword2 = false;
    }else{
      this.verPassword2 = true;
    }
  }

  onSubmit() {
    this.submitted = true;
    this.error='';

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {

      if(this.passwordIguales()){
        this.passwrodDiferentes = true;
      }
        return;
    }else{
      this.passwrodDiferentes = false;
    }
    
    this.usuario.password=this.f.password.value;
    this.usuario.codigoEmpleado=this.f.codigoEmpleado.value;

    this.loading = true;
    this.verPassword = false;
    this.verPassword2 = false;
    this.authenticationService.resetPassword(this.usuario)
        .pipe(first())
        .subscribe(
            data => {
                    this.router.navigate(["home"]);
            },
            error => {                
                this.error = error.error;
                this.loading = false;
            });
    }

    cancelar(){
      this.router.navigate([this.returnUrl]);

      if(this.returnUrl && this.returnUrl==='login'){
        this.authenticationService.logout();
      }
    }


  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
      let pass = group.controls.password.value;
      let confirmPass = group.controls.confirmPass.value;

    return pass === confirmPass ? null : { notSame: true }     
  }

  validarPass(){
    this.error='';
    if(this.passwordIguales()){
      this.passwrodDiferentes = true;
    }else{
      this.passwrodDiferentes = false;
    }
  }

  passwordIguales() { // here we have the 'passwords' group
      let pass = this.f.password.value;
      let confirmPass = this.f.confirmPass.value;

    return pass === confirmPass ? null : { notSame: true }     
  }

}
