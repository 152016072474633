import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AuthGuard } from './_guards/auth.guard.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RecuperarpasswordComponent } from './recuperarpassword/recuperarpassword.component';
import { ObjetivosComponent } from './objetivos/objetivos.component';
import { DatosgeneralesComponent } from './datosgenerales/datosgenerales.component';
import { ValoresComponent } from './valores/valores.component';
import { PlantillavaloresComponent } from './plantillavalores/plantillavalores.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ObjetivosSComponent } from './objetivos-s/objetivos-s.component';
import { RuedavidaComponent } from './ruedavida/ruedavida.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resetearPassword',
    component: ResetpasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'recuperarPassword',
    component: RecuperarpasswordComponent
  },
  {
    path: 'objetivos',
    component: ObjetivosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'objetivos_s',
    component: ObjetivosSComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'datosGenerales',
    component: DatosgeneralesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'valores',
    component: ValoresComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'plantillavalores',
    component: PlantillavaloresComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard]
  },
    {
    path: 'ruedavida',
    component: RuedavidaComponent,
    canActivate: [AuthGuard]
  },  
  { path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(routes,{useHash: true});
