import { Injectable } from '@angular/core';
import { Periodo } from '../clases/periodo';

@Injectable({
  providedIn: 'root'
})
export class StorageServiceService {

  constructor() { }

  guardarPeriodoActual(periodoActual: Periodo) {
    localStorage.setItem('periodoActual', JSON.stringify(periodoActual));
  }

  guardarAnioActual(anioActual: number) {
    localStorage.setItem('anioActual', JSON.stringify(anioActual));
  }

  obtenerPeriodoActual(): Periodo {
    return JSON.parse(localStorage.getItem('periodoActual'));
  }

  obtenerAnioActual(): number {
    return +localStorage.getItem('anioActual');
  }

}


