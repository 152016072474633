import { Component, OnInit } from '@angular/core';
import { UsuarioLogin } from '../clases/usuario-login';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { first } from 'rxjs/operators';
import { interval } from 'rxjs';

@Component({
  templateUrl: './recuperarpassword.component.html',
  styleUrls: ['./recuperarpassword.component.css']
})
export class RecuperarpasswordComponent implements OnInit {

  recuperarPasswordForm: FormGroup;
  usuario: UsuarioLogin;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  success = '';

  constructor(private formBuilder: FormBuilder,
    private router: Router, public authenticationService : ClienteWebServiceService) { }

  ngOnInit() {

    this.recuperarPasswordForm = this.formBuilder.group({
      codigoEmpleado: ['', Validators.required],
      email: ['',[Validators.required, Validators.email]]
    });

  }

  get f() { return this.recuperarPasswordForm.controls; }

  onSubmit(){

    this.submitted = true;

    if (this.recuperarPasswordForm.invalid) {
      return;
    }
    
    this.loading = true;

    this.authenticationService.recuperarPassword(this.f.email.value, this.f.codigoEmpleado.value)
    .pipe(first())
    .subscribe(
        data => {
            this.success = data.respuesta;
            this.loading = false;
            this.error = '';
            this.recuperarPasswordForm.reset();
            this.submitted = false;

        },
        error => {                
            this.error = error.error;       
            console.log(error);
            this.loading = false;
            this.success = '';
        });

  }

  cancelar(){
    this.router.navigate(["login"]);
  }

}
