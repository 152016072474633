import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output } from '@angular/core';
import '../../js/jquery.steps.js';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';
import { StorageServiceService } from '../services/storage-service.service.js';
import { Periodo } from '../clases/periodo.js';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioLogin } from '../clases/usuario-login.js';
import { ObjetivoUsuario } from '../clases/objetivo-usuario.js';
import { Plan } from '../clases/plan.js';
import { interval } from 'rxjs';
import { ObjetivosIngresados } from '../clases/objetivos-ingresados.js';
import { ActividadObjetivo } from '../clases/actividad-objetivo.js';
import { UdpCurrencyMaskPipe } from '../_guards/udp-currency-mask-pipe';
import html2canvas from 'html2canvas';
import { componentRefresh } from '@angular/core/src/render3/instructions';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { UtilServiceService } from '../services/util-service.service.js';
import { formatDate } from '@angular/common';

declare var $: any;
declare var steps: any;

@Component({
  selector: 'object-tivos',
  templateUrl: './objetivos.component.html',
  styleUrls: ['./objetivos.component.css']
})
export class ObjetivosComponent implements OnInit, AfterViewInit {

  //diagrama
  chartLabels:any;
  chartData: any;
  respuesta: any;
  objetivos:string[];
  planSolicitado : boolean;

  closeResult: string;
  idPeriodoActual: number;
  periodoActual: Periodo;
  periodoActualTmp: Periodo;
  anioActual: number;
  anioFinPeriodo: string;
  periodos: Periodo[];
  formGroup: FormGroup;
  formGroupAnio: FormGroup;
  anios: number[];
  aniosActivos: number[];
  submit = false;
  usuario: UsuarioLogin;
  avanceAnioActual: number = 0;
  avanceAnioPeriodo: number = 0;
  mesajeGuardarPerido: string;
  imagen : Blob;

  //Forma objetivo
  submitObjetivo = false;
  formGrupoObjetivos: FormGroup;
  objetivoUsuario: ObjetivoUsuario;
  idObjetivoSeleccionado: number = 1;
  nombreObjetivo: string = "Ahorro";
  plan: Plan;
  errorObjetivo: string;
  mensajeObjetivo: string;
  tipoCampo: String = 'N';
  objetivosIngresados: ObjetivosIngresados[];
  objetivosIngresadosTmp: ObjetivosIngresados[];
  actualizarOb = false;
  loading = false;

  planCerrado = false;

  //Forma Actividad
  formGrupoActividad: FormGroup;
  actividadObjetivo: ActividadObjetivo;
  actualizarAc = false;
  submitActividad = false;
  mensajeActividad = '';
  errorActividad = '';
  idActividadSeleccionada: number

  //Forma Actividad Trimestral
  formGrupoActividadTrimestral: FormGroup;
  actualizarAcTrim = false;
  submitActividadTrim = false;
  mensajeActividadTrim = '';
  errorActividadTrim = '';
  idActividadSeleccionadaTrim = 2;

  //Forma Actividad Entregable
  formGrupoEntregable: FormGroup;
  actualizarEntregable = false;
  submitEntregable = false;
  mostrarMensaje = false;
  mensajeEntregable = '';
  errorEntregable = '';
  idActividadEntregable = 2;
  actividadesTrimestrale: ActividadObjetivo[];
  actividadTrimestralSeleccionada: ActividadObjetivo;
  actividadesEntregables: ActividadObjetivo[];
  actividadEntregablesSeleccionado: ActividadObjetivo;
  mensajeEliminar = '';
  errorEliminar = '';

  //cerrar Plan
  submitCerrarPlan = false;
  submitEnviarPlan = false;
  mensajeCerrarPlan = '';
  mensajeEnviarPlan = '';
  errorCerrarPlan = '';
  errorEnviarPlan = '';

  error='';

  idObjetivoParametero: string;
  nombreObjetivoParameter:string;
  grupo:string = "Capital Financiero";
  grupoObjetivoParameter:string;

  //Actividades Trimestrales

  actiMenu = false;
  mostrarLayoutHome = true;

  imagenBase64 : String;
  hideOpcion: false;
  
  mostrarRueda = true;

  planActual: Plan;

  redirec: String = "S";

  constructor(public utilServiceService : UtilServiceService,
    private formBuilder: FormBuilder, private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute,
    public httpService: ClienteWebServiceService,
    private storageService: StorageServiceService, private currencyMask: UdpCurrencyMaskPipe) {
    
    this.planSolicitado = false;
    this.usuario = JSON.parse(localStorage.getItem('currentUser'));
    this.idObjetivoParametero = this.route.snapshot.queryParams['obj'];
    this.nombreObjetivoParameter = this.route.snapshot.queryParams['objNombre'];
    this.grupoObjetivoParameter = this.route.snapshot.queryParams['grupo'];
    if(this.route.snapshot.queryParams['redirec']){
      this.redirec = this.route.snapshot.queryParams['redirec']
    }

    if (this.usuario) {
      if(this.usuario.roles === null){
        this.router.navigate(['/home']);
      }

      if(this.usuario.ingresoDetallado==='N' && this.redirec==='S'){
        if(this.idObjetivoParametero){
        this.router.navigate(['/objetivos_s'],{queryParams: {obj:this.idObjetivoParametero ,objNombre:this.nombreObjetivoParameter,grupo:this.grupoObjetivoParameter }});
        }else{
          this.router.navigate(['/objetivos_s']);
        }
      }
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }

    this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          // trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
          // if you need to scroll back to top, here is the right place
          window.scrollTo(0, 0);
        }
    });
  }


  ngOnInit() {
    if(this.idObjetivoParametero){
      this.idObjetivoSeleccionado = parseInt(this.idObjetivoParametero, 10);
      this.nombreObjetivo=this.nombreObjetivoParameter;
      this.grupo=this.grupoObjetivoParameter;
    }

    this.efectoTab();
  }

  efectoTab() {
    $(this.agregarClase());
  }

  agregarClase() {
    $("#wizard").steps({
      headerTag: "h2",
      bodyTag: "section",
      transitionEffect: "fade",
      enableAllSteps: true,
      transitionEffectSpeed: 500,
      labels: {
        finish: "Guardar",
        next: "Siguinente",
        previous: "Anterior"
      }
    });

    $('.wizard > .steps li a').click(function () {
      $(this).parent().addClass('checked');
      $(this).parent().prevAll().addClass('checked');
      $(this).parent().nextAll().removeClass('checked');

    });
    // Custome Jquery Step Button
    $('.Siguiente').click(function () {
      $("#wizard").steps('next');
    })
    $('.Anterior').click(function () {
      $("#wizard").steps('previous');
    })
    // Select Dropdown
    $('html').click(function () {
      $('.select .dropdown').hide();
    });
    $('.select').click(function (event) {
      event.stopPropagation();
    });
    $('.select .select-control').click(function () {
      $(this).parent().next().toggle();
    })
    $('.select .dropdown li').click(function () {
      $(this).parent().toggle();
      var text = $(this).attr('rel');
      $(this).parent().prev().find('div').text(text);
    })
  }

  ngAfterViewInit(): void {
    if(this.usuario.ingresoDetallado!=='N'){
    this.obtenerPeriodos();
    }
  }

  objetivosYaIngresados() {

    if (this.periodoActual && this.anioActual) {
      this.httpService.obtenerObjetivosYaIngresados(this.anioActual, this.periodoActual.idPeriodo).subscribe(objetivosYaIngresados => {
        if (objetivosYaIngresados && objetivosYaIngresados.listaObjetivosIngresados) {
          this.objetivosIngresados = objetivosYaIngresados.listaObjetivosIngresados;
          this.objetivosIngresadosTmp = [];
          for (let objecTmp of this.objetivosIngresados) {
            this.objetivosIngresadosTmp[objecTmp.idObjetivo] = objecTmp;
          }
          this.objetivosIngresados = this.objetivosIngresadosTmp;
        } else {
          this.objetivosIngresados = null;
        }
        this.iniciarDatosObjetivo();
      },
        error => {
          console.log(error.error);
        }
      );
    }
  }


  iniciarDatosObjetivo() {

    if (this.periodoActual && this.anioActual) {

      this.httpService.obtenerInformacionDeObjetivo(this.anioActual, this.periodoActual.idPeriodo, this.idObjetivoSeleccionado).subscribe(objetivo => {
        this.objetivoUsuario = objetivo.objetivoUsuario;
        this.tipoCampo = objetivo.tipoCampo;
        this.planCerrado = false;
        this.planActual = objetivo;
        if(objetivo.ingresoDetallado==='N'){
          this.router.navigate(['/objetivos_s'],{ queryParams: { redirec: 'N' } });
        }

        if (this.objetivoUsuario) {
          this.actualizarOb = true;
        }else{
          this.actualizarOb = false;
        }

        if (objetivo.estado && objetivo.estado === 'C') {
          this.planCerrado = true;
        } else if (objetivo.estado && objetivo.estado === 'R') {
          this.planCerrado = true;
        }
        
        this.formGrupoObjetivos = this.formBuilder.group({
          idObjetivo: [this.objetivoUsuario ? this.objetivoUsuario.idObjetivoUsuario : '',],
          inicia: [{ value: this.objetivoUsuario ? this.objetivoUsuario.inicial : '0', disabled: this.objetivoUsuario ? true : false }, Validators.required],
          actual: [this.objetivoUsuario ? this.objetivoUsuario.actual : '0'],
          metaAnioActual: [this.objetivoUsuario ? this.objetivoUsuario.metaAnual : '0', Validators.required],
          metaDelPeriodo: [this.objetivoUsuario ? this.objetivoUsuario.metaFinPeriodo : '0', Validators.required],
          avanceAnioActual: [this.avanceAnioActual],
          avanceAnioPeriodo: [this.avanceAnioPeriodo]
        }); 
        
         if(this.idObjetivoSeleccionado ===1 || this.idObjetivoSeleccionado===2){

          const maskedVal = this.currencyMask.transform2(this.fObjetivos.inicia.value);
          if (this.fObjetivos.inicia !== maskedVal) {
            this.formGrupoObjetivos.patchValue({inicia: maskedVal});
          }        

          const maskedVal1 = this.currencyMask.transform2(this.fObjetivos.actual.value);
          if (this.fObjetivos.actual !== maskedVal1) {
            this.formGrupoObjetivos.patchValue({actual: maskedVal1});
          }        
          const maskedVal2 = this.currencyMask.transform2(this.fObjetivos.metaAnioActual.value);
          if (this.fObjetivos.metaAnioActual !== maskedVal2) {
            this.formGrupoObjetivos.patchValue({metaAnioActual: maskedVal2});
          }

          const maskedVal3 = this.currencyMask.transform2(this.fObjetivos.metaDelPeriodo.value);
          if (this.fObjetivos.metaDelPeriodo !== maskedVal3) {
            this.formGrupoObjetivos.patchValue({metaDelPeriodo: maskedVal3});
          }
          this.eventoActualizarValor();
        }
        this.calcularAvanceAnioActual();
        this.iniciarDatosPrioridadActividad();
      });
    }

  }

  eventoActualizarValor(){
    this.formGrupoObjetivos.valueChanges.subscribe(val => {
      if (typeof val.inicia === 'string') {            
        const maskedVal = this.currencyMask.transform(val.inicia);
        if (val.inicia !== maskedVal) {
          this.formGrupoObjetivos.patchValue({inicia: maskedVal});
        }
      }
      if (typeof val.actual === 'string') {            
        const maskedVal = this.currencyMask.transform(val.actual);
        if (val.actual !== maskedVal) {
          this.formGrupoObjetivos.patchValue({actual: maskedVal});
        }
      }
      if (typeof val.metaAnioActual === 'string') {            
        const maskedVal = this.currencyMask.transform(val.metaAnioActual);
        if (val.metaAnioActual !== maskedVal) {
          this.formGrupoObjetivos.patchValue({metaAnioActual: maskedVal});
        }
      }
      if (typeof val.metaDelPeriodo === 'string') {            
        const maskedVal = this.currencyMask.transform(val.metaDelPeriodo);
        if (val.metaDelPeriodo !== maskedVal) {
          this.formGrupoObjetivos.patchValue({metaDelPeriodo: maskedVal});
        }
      }
    });
  }

  get fObjetivos() { return this.formGrupoObjetivos.controls };

  calcularAvanceAnioActual() {

    let actual: number;
    let inicia: number;
    let metaAnioActual;
    let metaPeriodo;
    let acumulado;

    if(this.idObjetivoSeleccionado ===1 || this.idObjetivoSeleccionado===2){
    actual = this.fObjetivos.actual.value.replace('Q','');
    inicia = this.fObjetivos.inicia.value.replace('Q','');
    metaAnioActual = this.fObjetivos.metaAnioActual.value.replace('Q','');
    metaPeriodo = this.fObjetivos.metaDelPeriodo.value.replace('Q','');
    }else{
      actual = this.fObjetivos.actual.value;
      inicia = this.fObjetivos.inicia.value;
      metaAnioActual = this.fObjetivos.metaAnioActual.value;
      metaPeriodo = this.fObjetivos.metaDelPeriodo.value;
    }

    if (actual && metaAnioActual && metaAnioActual > 0) {
      this.avanceAnioActual = (actual * 100) / metaAnioActual;
    } else {
      this.avanceAnioActual = 0;
    }

    if (actual && metaPeriodo && metaPeriodo > 0) {
      this.avanceAnioPeriodo = actual * 100 / metaPeriodo;
    } else {
      this.avanceAnioPeriodo = 0;
    }

    this.avanceAnioActual = Math.round(this.avanceAnioActual);
    this.avanceAnioPeriodo = Math.round(this.avanceAnioPeriodo);

    if (this.avanceAnioActual >= 0)
      this.fObjetivos.avanceAnioActual.setValue(this.avanceAnioActual);


    if (this.avanceAnioPeriodo >= 0)
      this.fObjetivos.avanceAnioPeriodo.setValue(this.avanceAnioPeriodo);

  }

  onSelectObjetivo(idObjetivo: number, nombreObjetivo: string, grupo: string) {
    this.iniciarTab();
    this.idObjetivoSeleccionado = idObjetivo;
    this.nombreObjetivo = nombreObjetivo;
    this.grupo = grupo;
    this.formGrupoObjetivos = null;
    this.formGrupoEntregable = null;
    this.formGroup = null;
    this.formGrupoActividadTrimestral = null;
    this.formGrupoActividad = null;
    this.actividadesTrimestrale = null;
    //this.objetivosYaIngresados();
    this.obtenerPeriodos();
  }


  //guarda el objetivo 
  onSubmitObjetivo() {
    if (!this.planCerrado) {
      this.submitObjetivo = true;
      this.mostrarRueda = false;
      if (this.formGrupoObjetivos.invalid) {
        return;
      }

      this.objetivoUsuario = new ObjetivoUsuario();
      this.objetivoUsuario.idObjetivoUsuario = this.fObjetivos.idObjetivo.value;
      this.objetivoUsuario.objetivo = this.idObjetivoSeleccionado;

      if(this.idObjetivoSeleccionado ===1 || this.idObjetivoSeleccionado===2){
        this.objetivoUsuario.metaAnual = this.fObjetivos.metaAnioActual.value.replace('Q','');
        this.objetivoUsuario.metaFinPeriodo = this.fObjetivos.metaDelPeriodo.value.replace('Q','');
        this.objetivoUsuario.actual = this.fObjetivos.actual.value.replace('Q','');
      }else{
        this.objetivoUsuario.metaAnual = this.fObjetivos.metaAnioActual.value;
        this.objetivoUsuario.metaFinPeriodo = this.fObjetivos.metaDelPeriodo.value;
        this.objetivoUsuario.actual = this.fObjetivos.actual.value;
      }

      this.plan = new Plan();
      this.plan.anio = this.anioActual;
      this.plan.codigoEmpleado = this.usuario.codigoEmpleado;
      this.plan.nombreUsuario = this.usuario.nombreUsuario;
      this.plan.periodo = this.periodoActual.idPeriodo;
      this.plan.objetivoUsuario = this.objetivoUsuario;


      if (this.actualizarOb) {
        this.actualizarObjetivo();
      } else {
        this.guardarObjetivo();
      }
    }
  }

  guardarObjetivo() {
    if(this.idObjetivoSeleccionado ===1 || this.idObjetivoSeleccionado===2){
      this.objetivoUsuario.inicial = this.fObjetivos.inicia.value.replace('Q','');
    }else{
      this.objetivoUsuario.inicial = this.fObjetivos.inicia.value;
    }

    this.httpService.registrarPlanObjetivo(this.plan).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.mensajeObjetivo = 'Objetivo Guardado Exitosamente';
        //this.formGrupoObjetivos = null;
        this.objetivosYaIngresados();
        this.fObjetivos.inicia.disable();

      } else {
        this.errorObjetivo = respuesta.respuesta;
        this.calcularAvanceAnioActual();
      }
      this.submit = false;
      this.mostrarRueda = true;
    },
      error => {
        this.errorObjetivo = error.error;
        this.submit = false;
        this.mostrarRueda = true;
      }
    );
  }

  actualizarObjetivo() {
    this.httpService.actualizarPlanObjetivo(this.plan).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.mensajeObjetivo = 'Objetivo Actualizado Exitosamente';
        //this.formGrupoObjetivos = null;
        this.objetivosYaIngresados();
      } else {
        this.errorObjetivo = respuesta.respuesta;
        this.calcularAvanceAnioActual();
      }
      this.submit = false;
      this.mostrarRueda = true;
    },
      error => {
        this.errorObjetivo = error.error;
        this.submit = false;
        this.mostrarRueda = true;
      }
    );
  }

  //-------- Actividad -----------------------------

  iniciarDatosPrioridadActividad() {

    if (this.periodoActual && this.anioActual) {

      let idObj = this.objetivoUsuario ? this.objetivoUsuario.idObjetivoUsuario : -1;
      this.httpService.obtenerInformacionDeActividad(idObj, 1).subscribe(actividadObjetivo => {
        this.actividadObjetivo = actividadObjetivo;

        if (this.actividadObjetivo) {
          this.actualizarAc = true;
        } else {
          this.actualizarAc = false;
        }

        this.formGrupoActividad = this.formBuilder.group({
          idActividadObjetivo: [this.actividadObjetivo ? this.actividadObjetivo.idActividadObjetivo : ''],
          nombre: [this.actividadObjetivo ? this.actividadObjetivo.nombre : '', Validators.required],
          avance: [this.actividadObjetivo ? this.actividadObjetivo.avance : 0, Validators.required],
          estado: [this.actividadObjetivo && this.actividadObjetivo.estado ? this.actividadObjetivo.estado : 'N'],
          fechaEntrega: [this.actividadObjetivo && this.actividadObjetivo.fechaEntrega ? this.actividadObjetivo.fechaEntrega : '']
        });

        this.iniciarDatosActividadTrimestaral();
      });
    }
  }

  get fActividad() { return this.formGrupoActividad.controls; };

  guardarPrioridadActividad() {
    
    if (!this.planCerrado) {
      this.submitActividad = true;
      if (this.formGrupoActividad.invalid) {
        return;
      }

      this.actividadObjetivo = new ActividadObjetivo();
      this.actividadObjetivo.avance = this.fActividad.avance.value;
      this.actividadObjetivo.nombre = this.fActividad.nombre.value;
      this.actividadObjetivo.idObjetivo = this.objetivoUsuario.idObjetivoUsuario;
      this.actividadObjetivo.fechaEntrega = this.fActividad.fechaEntrega.value;
      this.actividadObjetivo.idActividadObjetivo = this.fActividad.idActividadObjetivo.value;
      this.actividadObjetivo.idActivida = 1;

      if (this.fActividad.estado.value && this.fActividad.estado.value) {
        this.actividadObjetivo.estado = this.fActividad.estado.value;
      }

      if (this.actualizarAc) {
        this.actualizarActividad(this.actividadObjetivo, null);
      } else {
        this.registrarActividad(this.actividadObjetivo, null);
      }
    }
  }

  registrarActividad(actividadObjetivo: ActividadObjetivo, entregable:string) {
    this.httpService.registrarActividad(actividadObjetivo).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.mensajeActividad = 'Objetivo Registrado Exitosamente';
        if(typeof entregable === 'undefined' || entregable === null){
          this.iniciarDatosPrioridadActividad(); 
        }else{
          this.obtenerEntregable();
        }

        if (this.formGrupoEntregable) {
          this.limpiarForEntregable();
        }
      } else {
        this.errorActividad = respuesta.respuesta;
      }
    },
      error => {
        console.log(error);
        this.errorActividad = error.error;
      }
    );
  }


  actualizarActividad(actividadObjetivo: ActividadObjetivo, entregable:string) {
    this.httpService.actualizarActividad(actividadObjetivo).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.mensajeActividad = 'Objetivo Actualizado Exitosamente';
        if(typeof entregable === 'undefined' || entregable === null){
          this.iniciarDatosPrioridadActividad();
        }else{
          this.obtenerEntregable();
        }

        if (this.formGrupoEntregable) {
          this.limpiarForEntregable();
        }
      } else {
        this.errorActividad = respuesta.respuesta;
      }
    },
      error => {
        this.errorActividad = error.error;
      }
    );
  }

  //// Actividad Trimestral

  iniciarDatosActividadTrimestaral() {

    if (this.periodoActual && this.anioActual) {

      let idObj = this.objetivoUsuario ? this.objetivoUsuario.idObjetivoUsuario : -1;
      this.httpService.obtenerInformacionDeActividad(idObj, this.idActividadSeleccionadaTrim).subscribe(actividadObjetivo => {

        if (actividadObjetivo) {
          this.actualizarAcTrim = true;
        } else {
          this.actualizarAcTrim = false;
        }

        this.formGrupoActividadTrimestral = this.formBuilder.group({
          actividadTrimestralSel: [this.idActividadSeleccionadaTrim],
          idActividadObjetivo: [actividadObjetivo ? actividadObjetivo.idActividadObjetivo : ''],
          nombre: [actividadObjetivo ? actividadObjetivo.nombre : '', Validators.required],
          avance: [actividadObjetivo ? actividadObjetivo.avance : 0, Validators.required],
          estado: [actividadObjetivo && actividadObjetivo.estado ? actividadObjetivo.estado : 'N'],
          fechaEntrega: [actividadObjetivo && actividadObjetivo.fechaEntrega ? actividadObjetivo.fechaEntrega : '']
        });
        this.obtenerListaActividadesTrimestrales();
      });
    }
  }

  onChangeTrimestral(idActividad: number) {
    this.idActividadSeleccionadaTrim = idActividad;
    this.iniciarDatosActividadTrimestaral();
  }

  get fActividadTrimestral() { return this.formGrupoActividadTrimestral.controls };

  guardarActividadTrimestral() {
    if (!this.planCerrado) {
      this.submitActividadTrim = true;
      if (this.formGrupoActividadTrimestral.invalid) {
        return;
      }

      this.actividadObjetivo = new ActividadObjetivo();
      this.actividadObjetivo.avance = this.fActividadTrimestral.avance.value;
      this.actividadObjetivo.nombre = this.fActividadTrimestral.nombre.value;
      this.actividadObjetivo.idObjetivo = this.objetivoUsuario.idObjetivoUsuario;
      this.actividadObjetivo.fechaEntrega = this.fActividadTrimestral.fechaEntrega.value;
      this.actividadObjetivo.idActividadObjetivo = this.fActividadTrimestral.idActividadObjetivo.value;
      this.actividadObjetivo.idActivida = this.idActividadSeleccionadaTrim;

      if (this.fActividadTrimestral.estado.value && this.fActividadTrimestral.estado.value) {
        this.actividadObjetivo.estado = this.fActividadTrimestral.estado.value;
      }

      if (this.actualizarAcTrim) {
        this.actualizarActividad(this.actividadObjetivo,null);
      } else {
        this.registrarActividad(this.actividadObjetivo,null);
      }
    }

  }

  ///Entregables

  iniciarDatosActividadEntregables() {

    if (this.periodoActual && this.anioActual) {

      let idObj = this.objetivoUsuario ? this.objetivoUsuario.idObjetivoUsuario : -1;
      let actividadObjetivo = this.actividadEntregablesSeleccionado;

      if (actividadObjetivo) {
        this.actualizarEntregable = true;
      } else {
        this.actualizarEntregable = false;
      }
      
      this.formGrupoEntregable = this.formBuilder.group({
        actividadEntregable: [this.actividadTrimestralSeleccionada],
        idActividadObjetivo: [actividadObjetivo ? actividadObjetivo.idActividadObjetivo : ''],
        nombre: [actividadObjetivo ? actividadObjetivo.nombre : '', Validators.required],
        avance: [actividadObjetivo ? actividadObjetivo.avance : 0, Validators.required],
        estado: [actividadObjetivo && actividadObjetivo.estado ? actividadObjetivo.estado : 'N'],
        fechaEntrega: [actividadObjetivo && actividadObjetivo.fechaEntrega ? actividadObjetivo.fechaEntrega : '', Validators.required]
      });

      

    }
    this.toolTip();
  }

  iniciarForEntregable() {
    this.fEntregable.idActividadObjetivo.setValue(this.actividadEntregablesSeleccionado.idActividadObjetivo);
    this.fEntregable.nombre.setValue(this.actividadEntregablesSeleccionado.nombre);
    this.fEntregable.avance.setValue(this.actividadEntregablesSeleccionado.avance);
    this.fEntregable.estado.setValue(this.actividadEntregablesSeleccionado.estado);
    this.fEntregable.fechaEntrega.setValue(this.actividadEntregablesSeleccionado.fechaEntrega);
  }

  limpiarForEntregable() {
    this.fEntregable.idActividadObjetivo.setValue('');
    this.fEntregable.nombre.setValue('');
    this.fEntregable.avance.setValue('0');
    this.fEntregable.estado.setValue('N');
    this.fEntregable.fechaEntrega.setValue('');
    this.submitEntregable = false;
    this.actualizarEntregable = false;
  }

  seleccionarEntregable(entregableSeleccionado: ActividadObjetivo) {
    this.actividadEntregablesSeleccionado = entregableSeleccionado;
    this.actualizarEntregable = true;
    this.iniciarForEntregable();
  }

  onChangeAcTrimestral(actTrimes: ActividadObjetivo) {
    this.actividadTrimestralSeleccionada = actTrimes;
    this.formGrupoEntregable = null;
    this.actividadEntregablesSeleccionado = null;
    this.actividadesEntregables = null;
    this.obtenerIdObjetivo();
    this.iniciarDatosActividadEntregables();
    this.obtenerEntregable();
  }

  obtenerListaActividadesTrimestrales() {

    this.httpService.obtenerActividadesTrimestrales(this.objetivoUsuario ? this.objetivoUsuario.idObjetivoUsuario : -1).subscribe(trimestrales => {
      this.actividadesTrimestrale = trimestrales.actividadesObjetivos;

      if (this.actividadesTrimestrale && this.actividadTrimestralSeleccionada === undefined) {
        this.actividadTrimestralSeleccionada = this.actividadesTrimestrale[0];
        if(this.actividadTrimestralSeleccionada){
             this.obtenerIdObjetivo();
        }
      }

      if (this.formGrupoEntregable) {
        this.limpiarForEntregable();
      }else{
        this.iniciarDatosActividadEntregables();
      }
      this.obtenerEntregable();
      

    },
      error => {
        console.log(error.message);
      }

    )
  }

  obtenerEntregable() {
    if (this.actividadTrimestralSeleccionada && this.idActividadSeleccionada) {

      this.httpService.obtenerActividadesEntregables(this.objetivoUsuario ? this.objetivoUsuario.idObjetivoUsuario : -1, this.idActividadSeleccionada).subscribe(entregables => {
        this.actividadesEntregables = entregables.actividadesObjetivos;
      })
    }
  }

  obtenerIdObjetivo(){
    if (this.actividadTrimestralSeleccionada.idActivida === 2) {
      this.idActividadSeleccionada = 6;
    }

    if (this.actividadTrimestralSeleccionada.idActivida === 3) {
      this.idActividadSeleccionada = 7;
    }

    if (this.actividadTrimestralSeleccionada.idActivida === 4) {
      this.idActividadSeleccionada = 8;
    }

    if (this.actividadTrimestralSeleccionada.idActivida === 5) {
      this.idActividadSeleccionada = 9;
    }
  }

  get fEntregable() { return this.formGrupoEntregable.controls }

  guardarActividadEntregable() {
    if (!this.planCerrado) {
      this.submitEntregable = true;
      this.mostrarMensaje = true;
      
      if (this.formGrupoEntregable.invalid) {
        return;
      }

      this.actividadObjetivo = new ActividadObjetivo();
      this.actividadObjetivo.avance = this.fEntregable.avance.value;
      this.actividadObjetivo.nombre = this.fEntregable.nombre.value;
      this.actividadObjetivo.idObjetivo = this.objetivoUsuario.idObjetivoUsuario;
      this.actividadObjetivo.fechaEntregaTexto = formatDate(this.fEntregable.fechaEntrega.value,'dd-MM-yyyy','en');
      this.actividadObjetivo.idActividadObjetivo = this.fEntregable.idActividadObjetivo.value;
      this.actividadObjetivo.idActivida = this.idActividadSeleccionada;

      if (this.fEntregable.estado.value && this.fEntregable.estado.value) {
        this.actividadObjetivo.estado = this.fEntregable.estado.value;
      }

      this.actividadEntregablesSeleccionado = null;
      if (this.actualizarEntregable) {
        this.actualizarActividad(this.actividadObjetivo,"E");
      } else {
        this.registrarActividad(this.actividadObjetivo,"E");
      }
    }

  }

  eliminarEntregable(entregableSeleccionado: ActividadObjetivo) {

    this.httpService.eliminarActividad(entregableSeleccionado).subscribe(respuesta => {
      if (respuesta.respuesta === 'ok') {
        this.mensajeEliminar = 'Actividad Eliminada Exitosamente';
        this.obtenerEntregable();
        this.intervalMensajeEliminar();
      } else {
        this.errorEliminar = respuesta.respuesta;
      }
    },
      error => {
        this.errorEliminar = error.error;
      }
    );

  }

  intervalMensajeEliminar() {
    var secondsCounter = interval(5000);
    secondsCounter.subscribe(n => {
      this.errorEliminar = '';
      this.mensajeEliminar = '';
    }
    );
  }

  intervalMensajeActivo() {
      this.errorObjetivo = '';
      this.mensajeObjetivo = '';
      this.mensajeActividad = '';
      this.errorActividad = '';
      this.submitActividad = false;
      this.submitActividadTrim = false;
      this.submitEntregable = false;
      this.mostrarMensaje = false;
      this.mensajeEntregable = '';
      this.errorEntregable = '';
  }


  //---------- Ventana Modal Periodo ----------------------------
  obtenerPeriodos() {
    this.httpService.obtenerPeriodos().subscribe(periodo => {
      this.periodos = periodo.periodos;

      if (this.anioActual === undefined) {
        this.anioActual = periodo.anioActual;
      }

      if (this.idPeriodoActual === undefined) {
        this.idPeriodoActual = periodo.idPeriodoActual;
      }
      
      //Valida que tenga un periodo ingresado
      if (this.idPeriodoActual) {
        this.periodoActual = this.periodos.filter(periodo => { return periodo.idPeriodo = this.idPeriodoActual })[0];
        this.anios = [];
        this.anioFinPeriodo = this.periodoActual.etiqueta;

        for (let a = this.periodoActual.inicio; a < (this.periodoActual.inicio + this.periodoActual.duracion); a++) {
              this.anios.push(a);          
        }
      }

      //Se crea la forma
      this.formGroup = this.formBuilder.group({
        periodo: new FormControl(this.periodoActual ? this.periodoActual : '', Validators.required),
        anio: [this.anioActual ? this.anioActual : '', Validators],
        enviar: ['E']
      });

      this.objetivosYaIngresados();
      this.obtenerPeriodosActivos();
    },
      error => {
        console.log(error);
      });
  }

  toolTip(){
    $('#wizard-t-0').attr("data-toggle","tooltip");
    $('#wizard-t-0').attr("title","Objetivos");
    $('#wizard-t-1').attr("data-toggle","tooltip");
    $('#wizard-t-1').attr("title","Prioridades Anuales");
    $('#wizard-t-2').attr("data-toggle","tooltip");
    $('#wizard-t-2').attr("title","Prioridades Trimestrales");
    $('#wizard-t-3').attr("data-toggle","tooltip");
    $('#wizard-t-3').attr("title","Entregables");
  }

  obtenerPeriodosActivos() {

      //Valida que tenga un periodo ingresado
      if (this.idPeriodoActual) {
        this.aniosActivos = [];
        
        for (let a = this.periodoActual.inicio; a < (this.periodoActual.inicio + this.periodoActual.duracion); a++) {

          this.httpService.obtenerEstadoAnio(a,this.periodoActual.idPeriodo).subscribe(respuesta =>{
            if(respuesta && respuesta.respuesta ==='A'){
              this.aniosActivos.push(a);
            }
            this.aniosActivos = this.aniosActivos.sort((a,b)=>{
              return a-b;
            });
          });
          
        }
      }

      //Se crea la forma
      this.formGroupAnio = this.formBuilder.group({
        periodo: new FormControl(this.periodoActual ? this.periodoActual : '', Validators.required),
        anio: [this.anioActual ? this.anioActual : '', Validators]
      });

     // this.objetivosYaIngresados();
  }


  get fAnio() {
    return this.formGroupAnio.controls;
  }

  get f() {
    return this.formGroup.controls;
  }

  onChange(periodo) {
    this.periodoActual = periodo;
    this.anios = [];
    for (let a = this.periodoActual.inicio; a < (this.periodoActual.inicio + this.periodoActual.duracion); a++) {
          this.anios.push(a);
    }
  }

  onChangeAnioActivo(periodo) {
    this.periodoActual = periodo;
    this.aniosActivos = [];
        
    for (let a = this.periodoActual.inicio; a < (this.periodoActual.inicio + this.periodoActual.duracion); a++) {

      this.httpService.obtenerEstadoAnio(a,this.periodoActual.idPeriodo).subscribe(respuesta =>{
        if(respuesta && respuesta.respuesta ==='A'){
          this.aniosActivos.push(a);
        }
        
        this.aniosActivos = this.aniosActivos.sort((a,b)=>{
          return a-b;
        });
      });
      
    }
  }

  onSubmited() {
    this.submit = true;
    this.mostrarRueda = false;

    if (this.formGroup.errors) {
      return;
    }
    this.mesajeGuardarPerido = '';
    this.error = '';
    this.periodoActual = this.f.periodo.value;
    this.anioActual = this.f.anio.value
    this.storageService.guardarPeriodoActual(this.periodoActual);
    this.storageService.guardarAnioActual(this.anioActual);
    this.formGrupoObjetivos = null;
    this.formGrupoEntregable = null;
    this.formGrupoActividadTrimestral = null;
    this.formGrupoActividad = null;
    this.actividadesTrimestrale = null;
    this.activarPlan();
  }

  activarPlan() {

    this.plan = new Plan();
    this.plan.anio = this.anioActual;
    this.plan.codigoEmpleado = this.usuario.codigoEmpleado;
    this.plan.nombreUsuario = this.usuario.nombreUsuario;
    this.plan.periodo = this.periodoActual.idPeriodo;
    
    this.httpService.activarPlan(this.plan).subscribe(respuesta => {
      this.mesajeGuardarPerido = "Periodo almacenado correctamente.";
      this.iniciarDatosObjetivo();
      this.objetivosYaIngresados();
      this.iniciarTab();
      this.mostrarRueda = true;
    },
      error => {
        this.error = error.error;
        this.mostrarRueda = true;
      });

  }  

  onSubmitedCerrarPlan() {
    this.submitCerrarPlan = true;
    this.errorCerrarPlan = '';

    if (this.formGroupAnio.errors) {
      return;
    }


    this.plan = new Plan();
    this.plan.anio = this.fAnio.anio.value;
    this.plan.codigoEmpleado = this.usuario.codigoEmpleado;
    this.plan.nombreUsuario = this.usuario.nombreUsuario;
    this.periodoActualTmp = this.fAnio.periodo.value;
    this.plan.periodo = this.periodoActualTmp.idPeriodo;

    this.httpService.cerrarPlan(this.plan).subscribe(respuesta => {
      this.mensajeCerrarPlan = "Plan cerrado exitosamente.";
      this.iniciarDatosObjetivo();
    },
      error => {
        this.errorCerrarPlan = error.error;
      });

  }

  onSubmitedEnviarPlan() {
    this.submitCerrarPlan = true;
    this.errorEnviarPlan = '';
    this.mensajeEnviarPlan = '';
    
    if (this.formGroup.errors) {
      return;
    }

    this.loading = true;
    this.plan = new Plan();
    this.plan.anio = this.f.anio.value;
    this.plan.codigoEmpleado = this.usuario.codigoEmpleado;
    this.plan.nombreUsuario = this.usuario.nombreUsuario;
    this.periodoActualTmp = this.f.periodo.value;
    this.plan.periodo = this.periodoActualTmp.idPeriodo;

    if(this.planSolicitado){
        html2canvas(document.querySelector("#divAImagen"), {scrollY: -window.scrollY}).then(canvas => {
          this.imagenBase64 = canvas.toDataURL("image/png");
          this.plan.imagenBase64 = this.imagenBase64;
          this.enviarPlanDescargar();      
        }, error => {
          this.planSolicitado = false;
          this.loading = false;
          console.error(error)
        }) ;
    }else{
      this.plan.imagenBase64 = this.imagenBase64;
      this.enviarPlanDescargar();
    }
  }

  enviarPlanDescargar(){
    this.planSolicitado = false;
        if(this.f.enviar.value==='E'){
          this.httpService.enviarPlan(this.plan).subscribe(respuesta => {
            this.mensajeEnviarPlan = "Plan enviado exitosamente."
            this.loading = false;
          },
            error => {
              this.errorEnviarPlan = error.error;
              this.loading = false;
            });
          }else if(this.f.enviar.value==='D'){
            this.httpService.descargarPlanUrl(this.plan).subscribe(async respuesta => {
             await this.httpService.descargarPlan(respuesta.respuesta);
             this.loading = false;
            },
              error => {
                this.errorEnviarPlan = error.error;
                this.loading = false;
              });
          }
  }


  llenarRueda(anio) {
    
    this.anioActual = anio;
    this.respuesta = null;
    this.chartData = null;
    this.chartLabels = null;
    this.planSolicitado = true;

    this.httpService.obtenerDatosGraficaOtrosAños(anio).subscribe(resultado =>{
      this.respuesta = resultado;
      
      if(this.respuesta){
        this.chartData = resultado.datos;
        this.chartLabels = resultado.chartLabels;

        if(resultado.datosYaIngresados){
        this.objetivos = [];
          for(let label of resultado.datosYaIngresados){
            var key=label.replace(/\s/g, '');
            this.objetivos[key]=label;
          }
        }
      }
      

      
  }, error => {
    this.planSolicitado = false;
  });
}

  downloadFile2(data: any){
    var blob = new Blob([data], { type: 'application/pdf' });
    var url= window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
  }
  }

  iniciarTab(){
    $('#wizard-t-0').trigger('click');
  }

  logout() {
    this.httpService.logout();
    this.router.navigate(['/login']);
  }

  public open(content, nombre) {
    this.submit = false;
    this.submitEnviarPlan = false;
    this.submitCerrarPlan = false;
    this.mesajeGuardarPerido = '';
    this.mensajeCerrarPlan = '';
    this.mensajeEnviarPlan = '';
    this.errorEnviarPlan = '';
 
    if(nombre == 'planEnviar' ) {
        this.llenarRueda(this.anioActual);
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).
      result.then((result) => {
        //this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  activarMenu() {
    if (this.actiMenu) {
      this.actiMenu = false;
    } else {
      this.actiMenu = true;
    }
  }

}
