import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse } from '@angular/common/http';
import { ClienteWebServiceService } from '../services/cliente-web-service.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {  
  constructor(private authenticationService: ClienteWebServiceService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
                
            });
        }
    return next.handle(request);
  }
}
