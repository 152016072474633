import { Component, OnInit } from '@angular/core';
import { RegistroUsuario } from './clases/registro-usuario';
import { ClienteWebServiceService } from './services/cliente-web-service.service';
import { UsuarioLogin } from './clases/usuario-login';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'PlanDeVidaGT';
  actiMenu = false;
  mostrarLayoutHome = true;
  usuario: UsuarioLogin;

  constructor(private modalService: NgbModal,
    private router: Router,
    private authenticationService: ClienteWebServiceService) {
    this.actiMenu = false;
    this.authenticationService.currentUser.subscribe(x => this.usuario = x);
  }



  ngOnInit(): void {

  } 

}